import { call, put } from "redux-saga/effects";
import { GroupActions, UIActions } from "../actions";
import { applyExportOptions, downloadCsvFile } from "../lib/dataExportUtils";
import { GroupService } from "../services/group.service";
import { RxService } from "../services/rx.service";

export function* getGroupList(action: ReturnType<typeof GroupActions.getList>) {
    try {
        yield put(UIActions.showLoader(true));
        const { data } = yield call(GroupService.getInstance().getGroups, action.payload);
        
        if (action.payload.exportOptions) {
            const dataWithOptions = applyExportOptions(action.payload.exportOptions, data.result)
            downloadCsvFile(action.payload.exportOptions.fileName, dataWithOptions)
            yield put(UIActions.showLoader(false));
            return;
        }
        
        yield put(GroupActions.setList(data));
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (action.payload.exportOptions) {
            yield put(GroupActions.setList(null));
        }
        console.log('GET GROUP LIST ERROR', err)
        if (err instanceof Error) {
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}

export function* getGroupDetails(action: ReturnType<typeof GroupActions.getGroupDetails>) {
    try {
        yield put(UIActions.showLoader(true));
        const { data } = yield call(GroupService.getInstance().getGroupDetails, action.payload.id);
        yield put(GroupActions.setGroupDetails(data));
        if(action.payload.success) action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        console.log('GET GROUP DETAILS ERROR', err)
        action.payload.error(`Failed to load details for shipment group id ${action.payload.id}`)
        if (err instanceof Error) {
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}

export function* updateGroupServiceType(action: ReturnType<typeof GroupActions.updateGroupServiceType>) {
    try {
        yield put(UIActions.showLoader(true));
        const { data } = yield call(GroupService.getInstance().updateGroupServiceType, action.payload);
        yield put(GroupActions.setGroupDetails(data));
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        console.log('GET GROUP DETAILS ERROR', err)
        action.payload.error(`Failed to update service type for shipment group id ${action.payload.id}`)
        if (err instanceof Error) {
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}

export function* getRxList(action: ReturnType<typeof GroupActions.getRxList>) {
    try {
        yield put(UIActions.showLoader(true));
        const { data } = yield call(RxService.getInstance().getRxList, action.payload.request);
        yield put(GroupActions.setRxList(data));
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        action.payload.error()
        yield put(GroupActions.setRxList(null));
        console.log('GET GROUP RX LIST ERROR', err)
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}
