import createReducer from './createReducer';
import { GroupActions } from '../actions'
import { GROUP } from '../constants/actions';


export const group = createReducer({}, {
  [GROUP.SET_LIST](state = {}, action: ReturnType<typeof GroupActions.setList>) {
    let formattedData = action.payload.result
		formattedData.map((row: any) => {
			row.workgroups = JSON.stringify(row.workgroups || '')
      return row
		})
    return { ...state, groupList: { ...action.payload, result: formattedData } }
  },
  [GROUP.SET_RX_LIST](state = {}, action: ReturnType<typeof GroupActions.setRxList>) {
    return { ...state, rxList: action.payload }
  }, 
  [GROUP.SET_GROUP_DETAILS](state = {}, action: ReturnType<typeof GroupActions.setGroupDetails>) {
    return { ...state, groupDetails: action.payload }
  },
});