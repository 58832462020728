import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import { Paper, makeStyles } from '@material-ui/core';
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
// import { GetUserPickerTimeFormat } from '../../hooks/functions';
import { connect } from 'react-redux';
import { addDays } from 'date-fns';

const styles = makeStyles((theme) => ({
  pickerStyles: {
    '& .react-daterange-picker__wrapper': {
      height: 0
    }
  }
}))

interface MobileDateRangePopupProps {
  setOpen: (value: boolean) => void
  onSelect: (dates: any) => void
  dates?: any
  properties: any
}

const MobileDateRangePopup: React.FC<MobileDateRangePopupProps> = (props: any) => {
  const classes = styles();
  const [state, setState] = React.useState<any>([
    props.dates?.startDate || new Date(),
    props.dates?.endDate || new Date(),
  ])

  const handleRangeChange = (ranges: any) => {
    setState(ranges)
    const dates = {
      startDate: addDays(ranges.selection.startDate.setHours(0, 0, 0, 0), 0),
      endDate: addDays(ranges.selection.endDate.setHours(23, 59, 59, 0), 0)
    }
    props.onSelect(dates)
    props.setOpen(false);
  }

  return (
    <div>
      <ClickAwayListener onClickAway={(event: any) => {
        if (event.target.id !== 'date_range') {
          props.setOpen(false);
        }
      }}>
        <Paper className={`date-range-picker`} style={{
          position: 'absolute',
          left: '0',
          top: '0',
          zIndex: 9999,
          margin: '0 auto'
        }}>
          <DateRangePicker
            className={classes.pickerStyles}
            onChange={handleRangeChange}
            value={state}
            // dateDisplayFormat={GetUserPickerTimeFormat(props.properties)}
            autoFocus={true}
          />
          {/* <DateRange
            onChange={handleRangeChange}
            months={1}
            maxDate={new Date()}
            direction="vertical"
            scroll={{ enabled: true }}
            ranges={state}
            editableDateInputs={true}
            shownDate={props.dates?.startDate || new Date()}
            dateDisplayFormat="yyyy-MM-dd"
            startDatePlaceholder="Start: YYYY-MM-DD"
            endDatePlaceholder="End: YYYY-MM-DD"
          /> */}
        </Paper>
      </ClickAwayListener>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  properties: (state.user && state.user.authUser && state.user.authUser.record && state.user.authUser.record.properties && state.user.authUser.record.properties instanceof Array) ? state.user.authUser.record.properties : [],
})

export default connect(mapStateToProps) (MobileDateRangePopup);