import React,{useEffect,useRef} from 'react';
import { useDispatch } from 'react-redux';
import BasePage from '../common/base';
import { InventoryReportContainer } from './inventory-report.container';
import { Container } from '@material-ui/core';
import { DashboardStyles } from '../../hooks/styles';
import {Constants} from "../../constants/Constants";
const InventoryReport = () => {
  const classes = DashboardStyles();
  const isFirstRender = useRef(true)
  const dispatch = useDispatch();
  useEffect(() => {
    isFirstRender.current = false
  }, [dispatch])

  return (
    <BasePage title={Constants.PAGE_TITLE.INVENTORY_REPORT}>
      <div className={classes.appBarSpacer}></div>
      <Container maxWidth={false} className={classes.container}>
        <InventoryReportContainer />
      </Container>
    </BasePage>
  )
}

export default InventoryReport;