import { action } from 'typesafe-actions';
import { UI } from '../constants/actions';
import { AppMenuState } from '../models/common/ui';

export const setNavigationState     = (data: AppMenuState) => action(UI.NAVIGATE, data);
export const navigationStateSuccess = (data: AppMenuState) => action(UI.NAVIGATE_RESPONSE, data);
export const setNavDrawer = (open: boolean) => action(UI.SET_NAV_DRAWER, open);

export const setNavState = (navState: any) => action(UI.SET_NAV_STATE, navState)

export const showLoader = (isLoading : boolean) => action(UI.SHOW_LOADER, isLoading);

export const setUrlFilter = (queryString: string) => action(UI.SET_URL_FILTER, queryString);

export const setApiError = (payload: {errorMessage:string, severity:string, title:string} | null) => action(UI.SET_API_ERROR, payload);

export const setAuthProcessing = (payload: boolean) => action(UI.AUTH_PROCESSING, payload);

export const setGlobalRowsPerPage = (data: any) => action(UI.SET_GLOBAL_ROWS_PER_PAGE, data);

export const setSessionExpired = (flag: boolean) => action(UI.SESSION_EXPIRED, flag);

export const preLoadData = (payload: any) => action(UI.PRELOAD_DATA, payload)

export const setPreloadedCustomers = (data: any) => action(UI.SET_CUSTOMERS, data)

export const setPreloadedServiceTypes = (data: any) => action(UI.SET_PRELOADED_SERVICE_TYPES, data)

export const setPreloadedAddressTypes = (data: any) => action(UI.SET_PRELOADED_ADDRESS_TYPES, data)


export const setBackendPoller = (payload: any) => action(UI.SET_BACKEND_POLLER, payload)