import { action } from 'typesafe-actions';
import { RX } from '../constants/actions';

export const getAlertTransactions   = (filter: any) => action(RX.GET_ALERT_TRANSACTIONS, filter);
export const setAlertTransactions   = (data: any) => action(RX.SET_ALERT_TRANSACTIONS, data);
export const getRxExceptionsCount   = () => action(RX.GET_RX_EXCEPTION_COUNT);
export const setRxExceptionsCount   = (data: any) => action(RX.SET_RX_EXCEPTION_COUNT, data);
export const getRxList = (payload: any) => action(RX.GET_RX_LIST, payload);
export const setRxList = (payload: any) => action(RX.SET_RX_LIST, payload);
export const updateRx = (payload: any) => action(RX.UPDATE, payload);
export const resolveRx = (payload: any) => action(RX.RESOLVE, payload);
export const cancelRx = (payload: any) => action(RX.CANCEL, payload);
export const importReprocess = (payload: any) => action(RX.IMPORT_REPROCESS, payload);

