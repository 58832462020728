import { call, put, select } from "redux-saga/effects";
import { UIActions, UserManagementActions } from "../actions";
import { UserManagementService } from "../services/user-management.service";
import { FirebaseService } from "../services/firebase.service";

export function* getUsers(action: ReturnType<typeof UserManagementActions.getUsers>) {
    try {
        yield put(UIActions.showLoader(true));
        const { data } = yield call(UserManagementService.getInstance().getUsers, action.payload);
        yield put(UserManagementActions.setUsers(data));
        yield put(UIActions.showLoader(false));
    } catch (err) {
        yield put(UserManagementActions.setUsers(null));
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}

export function* inviteUser(action: ReturnType<typeof UserManagementActions.inviteUser>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(UserManagementService.getInstance().inviteUser, action.payload.requestBody);
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        action.payload.error()
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        }
    }
}

export function* updateUser(action: ReturnType<typeof UserManagementActions.updateUser>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(UserManagementService.getInstance().updateUser, action.payload.requestBody);
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        action.payload.error()
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        }
    }
}

export function* deleteUser(action: ReturnType<typeof UserManagementActions.deleteUser>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(UserManagementService.getInstance().deleteUser, action.payload.deleteId);
        action.payload.success();
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
        }
    }
}

export function* login(action: ReturnType<typeof UserManagementActions.login>): any {
    try {
        const { data } = yield call(UserManagementService.getInstance().login, action.payload.loginPayload);
        let authUser = data
        const { token, refreshToken } = yield call(FirebaseService.getInstance().getToken, authUser.customToken);
        authUser.apiToken = token
        authUser.refreshToken = refreshToken
        yield put(UserManagementActions.setAuthUser(authUser));
        sessionStorage.setItem('authUser', JSON.stringify(authUser))
        yield put(UIActions.setSessionExpired(false));
        action.payload.success();
    } catch (err: any) {
        console.log('LOGIN ERROR RESPONSE', err.response.data)
        let errorMessage = err.response.data
        if (err.response.data.includes('AcceptSecurityContext')) {
            errorMessage = 'Invalid login credentials.'
        }
        action.payload.error(errorMessage)
    }
}

export function* refreshSession(action: ReturnType<typeof UserManagementActions.refreshSession>): any {
    try {
        let authUser = yield select((state) => state.userManagement.authUser)
        console.log('REFRESH SESSION FOR', authUser.email)
        const token  = yield call(FirebaseService.getInstance().refreshToken, authUser.refreshToken);
        authUser.apiToken = token
        yield put(UserManagementActions.setAuthUser(authUser));
        sessionStorage.setItem('authUser', JSON.stringify(authUser))
        yield put(UIActions.setSessionExpired(false));
    } catch (err) {
       if (action.payload?.onError) {
        action.payload.onError()
       } else {
        yield put(UIActions.setSessionExpired(true));
       }
        console.log('REFRESH SESSION ERROR', err)
        // let errorMessage = err.response.data
    }
}

export function* logout(action: ReturnType<typeof UserManagementActions.logout>): any {
    const backendPoller = yield select((state) => state.ui.backendPoller)
    clearInterval(backendPoller)
    sessionStorage.removeItem('authUser')
    const url = window.location.pathname
    sessionStorage.setItem('url', url);
    yield window.location.href = '/login'
}

export function* pingUserService(action: ReturnType<typeof UserManagementActions.pingUserService>): any {
    try {
        let authUser = yield select((state) => state.userManagement.authUser)
        yield call(UserManagementService.getInstance().getUserById, authUser.id);
    } catch (err) {
        console.log('PING USER SERVICE ERROR', err)
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}
