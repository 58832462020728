import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router'
import { History } from 'history';

import * as uiReducer from './ui.reducer';
import * as productReducer from './product.reducer';
import * as userManagementReducer from './user-management.reducer';
import * as rxReducer from './rx.reducer';
import * as groupReducer from './group.reducer';
import * as workgroupReducer from './workgroup.reducer';
import * as reportReducer from './report.reducer';


const createRootReducer = (history: History<any>) => combineReducers(Object.assign(
  { router: connectRouter(history) },
  productReducer,
  userManagementReducer,
  uiReducer,
  rxReducer,
  groupReducer,
  workgroupReducer,
  reportReducer
));
export default createRootReducer;
