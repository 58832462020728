import createReducer from './createReducer';
import { RxActions } from '../actions'
import { RX } from '../constants/actions';


export const rx = createReducer({}, {
  [RX.SET_ALERT_TRANSACTIONS](state = {}, action: ReturnType<typeof RxActions.setAlertTransactions>) {
    let formattedData = action.payload.result || []
		formattedData.map((row: any) => {
			row.rxState = JSON.stringify(row.rxState || '')
      return row
		})
    return { ...state, alertTransactions: { ...action.payload, result: formattedData } }
  },

  [RX.SET_RX_EXCEPTION_COUNT](state = {}, action: ReturnType<typeof RxActions.setRxExceptionsCount>) {
    return { ...state, rxExceptionsCount: action.payload }
  },

  [RX.SET_RX_LIST](state = {}, action: ReturnType<typeof RxActions.setRxList>) {
    let formattedData = action.payload.result || []
		formattedData.map((row: any) => {
			row.rxState = JSON.stringify(row.rxState || '')
			row.rxGroup = JSON.stringify(row.rxGroup || '')
      return row
		})
    return { ...state, rxList: { ...action.payload, result: formattedData } }
  },
});