import { connect } from 'react-redux';
import { ProductActions, UIActions } from '../../redux/actions';
import { PackSetsComponent } from './pack-sets.component';

const mapStateToProps = (state: any) => ({
    authUser: state.userManagement.authUser,
    packSets: state.product.packSets ? state.product.packSets.result : [],
    totalPackSets: state.product.packSets ? state.product.packSets.total_records : 0,
    globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
  });
  
  const mapDispatchToProps = (dispatch: any) => ({
    getPackSets: (filter: any) => dispatch(ProductActions.getPackSets(filter)),
    deletePackSet: (payload: any) => dispatch(ProductActions.deletePackSet(payload)),
    setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIActions.setGlobalRowsPerPage(rowsPerPage)),
  });

export const PackSetsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackSetsComponent);