import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { IMAGES_URL } from '../../constants/Images';
import { UserManagementActions } from '../../redux/actions';
import { useHistory } from 'react-router-dom';
import Loading from '../../components/loading';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMsg: {
    color: '#f44336',
    fontWeight: 'bold',
    textAlign: 'center'
}
}), { index: 1 });


const LoginCard = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const [inputs, setInputs] = React.useState<any>({
    username: '',
    password: ''
  })
  const [formErrors, setFormErrors] = React.useState<any>({})
  const [loginError, setLoginError] = React.useState<string | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const onLogin = () => {
    const errors: any = {}
    Object.keys(inputs).forEach((input: string) => {
      if (!inputs[input]) {
        errors[input] = `${input} is required`
      }
    })
    setFormErrors(errors)

    if (Object.keys(errors).length > 0) {
      return
    }

    const payload = {
      loginPayload: {
        username: inputs.username,
        password: inputs.password
      },
      success: () => {
        setIsLoading(false)
        history.push('/')
      },
      error: (errorMessage: string) => {
        setLoginError(errorMessage)
        setIsLoading(false)
      }
    }

    setIsLoading(true)
    props.login(payload)
  }

  return (
    <Container component="main" maxWidth="xs" style={{ borderRadius: '6px', padding: 20 }}>
      <CssBaseline />
      <div className={classes.paper} style={{ marginTop: 0 }}>
        <img src={IMAGES_URL.NUFACTOR_LOGO} className="logo-image" alt="nufactor logo" />
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="User Name"
            name="username"
            autoFocus
            onChange={(event) => {
              setInputs({ ...inputs, username: event.target.value })
              setFormErrors({ ...formErrors, username: false })
              setLoginError(null)
            }}
            error={formErrors.username ? true : false}
            helperText={formErrors.username || ''}
            disabled={isLoading}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={(event) => {
              setInputs({ ...inputs, password: event.target.value })
              setFormErrors({ ...formErrors, password: false })
              setLoginError(null)
            }}
            error={formErrors.password ? true : false}
            helperText={formErrors.password || ''}
            disabled={isLoading}
          />
          {isLoading ?
            <Loading message="" />
            :
            <Button
              onClick={() => onLogin()}
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          }
        </form>
        {loginError && <p className={classes.errorMsg}>{loginError}</p>}
      </div>
    </Container>
  );
}
//export default LoginCard

const mapStateToProps = (state: any) => (
  {}
);
const mapDispatchToProps = (dispatch: any) => ({
  login: (payload: any) => dispatch(UserManagementActions.login(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(LoginCard);