import { connect } from 'react-redux';
import { ProductActions, UIActions } from '../../redux/actions';
import { ProductsComponent } from './products.component';

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
  products: state.product.products ? state.product.products.result : [],
  totalProducts: state.product.products ? state.product.products.total_records : 0,
  globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
  getProducts: (filter: any) => dispatch(ProductActions.getProducts(filter)),
  deleteProduct: (payload: any) => dispatch(ProductActions.deleteProduct(payload)),
  setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIActions.setGlobalRowsPerPage(rowsPerPage)),
});

export const ProductsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsComponent);