import Api from "../lib/api";
import { PRODUCTS } from '../constants/api.url';

export class ProductService {

  private static instance: ProductService;
  private constructor() { }

  public static getInstance(): ProductService {
    if (!ProductService.instance) {
      ProductService.instance = new ProductService();
    }
    return ProductService.instance;
  }

  public getProducts = async (filter: any): Promise<any> => {
    const params = [{ key: 'filter', value: JSON.stringify(filter) }];
    return await Api.get(PRODUCTS.PRODUCTS_URL, params)
  }

  public createProduct = async (payload: any): Promise<any> => {
    return await Api.post(PRODUCTS.PRODUCTS_URL, payload)
  }

  public updateProduct = async (payload: any): Promise<any> => {
    return await Api.patch(`${PRODUCTS.PRODUCTS_URL}/${payload.id}`, payload)
  }

  public deleteProduct = async (payload: any | null): Promise<any> => {
    return await Api.delete(`${PRODUCTS.PRODUCTS_URL}/${payload.deleteId}`, payload.deleteId);
  }

  public getPackingItems = async (filter: any): Promise<any> => {
    const params = [{ key: 'filter', value: JSON.stringify(filter) }];
    return await Api.get(PRODUCTS.PACKING_ITEMS_URL, params)
  }

  public createPackingItem = async (payload: any): Promise<any> => {
    return await Api.post(PRODUCTS.PACKING_ITEMS_URL, payload)
  }

  public updatePackingItem = async (payload: any): Promise<any> => {
    return await Api.patch(`${PRODUCTS.PACKING_ITEMS_URL}/${payload.id}`, payload)
  }

  public deletePackingItem = async (payload: any | null): Promise<any> => {
    return await Api.delete(`${PRODUCTS.PACKING_ITEMS_URL}/${payload.deleteId}`, payload.deleteId);
  }

  public getPackSets = async (filter: any): Promise<any> => {
    const params = [{ key: 'filter', value: JSON.stringify(filter) }];
    return await Api.get(PRODUCTS.PACK_SETS_URL, params)
  }

  public createPackSet = async (payload: any): Promise<any> => {
    return await Api.post(PRODUCTS.PACK_SETS_URL, payload)
  }

  public updatePackSet = async (payload: any): Promise<any> => {
    return await Api.patch(`${PRODUCTS.PACK_SETS_URL}/${payload.packSet.id}`, payload)
  }

  public deletePackSet = async (payload: any | null): Promise<any> => {
    return await Api.delete(`${PRODUCTS.PACK_SETS_URL}/${payload.deleteId}`, payload.deleteId);
  }
  
  public getServiceTypes = async (filter: any): Promise<any> => {
    const params = [{ key: 'filter', value: JSON.stringify(filter) }];
    return await Api.get(PRODUCTS.SERVICE_TYPES_URL, params)
  }

  public getAddressTypes = async (filter: any): Promise<any> => {
    const params = [{ key: 'filter', value: JSON.stringify(filter) }];
    return await Api.get(PRODUCTS.ADDRESS_TYPES_URL, params)
  }
}
