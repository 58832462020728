import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { BasePageStyles } from '../../../hooks/styles'
import { Avatar } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { UserManagementActions } from '../../../redux/actions';

const MenuListComposition = (props: any) => {
  const classes = BasePageStyles();
  const [open, setOpen] = React.useState(false);
  const [authenticatedUser, setAuthenticatedUser] = React.useState({} as any);
  const anchorRef = React.useRef(null);
  const history = useHistory();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: any) => {
    //if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //return;
    //}
    setOpen(false);
  };
  const handleLogout = (event: any) => {
   props.logout(history)
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      // setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      //anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open, props]);

  React.useEffect(() => {
    if (!props.authUser) {
      const sessionAuthUser = sessionStorage.getItem('authUser')
      const authUser = JSON.parse(sessionAuthUser || '{}')
      setAuthenticatedUser(authUser)
      props.setAuthUser(authUser)
    }
    setAuthenticatedUser(props.authUser)
  }, [props.authUser])

  return (
    <div>
      <Button
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.profileMenuBtn}
        style={{ color: 'white' }}
      >
        <Avatar className={classes.avatar}>
          {`${authenticatedUser?.firstName?.charAt(0) || ''}${authenticatedUser?.lastName?.charAt(0) || ''}`}
        </Avatar>
        <MenuItem ref={anchorRef} className={classes.profileName}>{`${authenticatedUser?.firstName || ''} ${authenticatedUser?.lastName || ''}`}</MenuItem>
        <ArrowDropDownIcon />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition placement="bottom-start" disablePortal >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
          >
            <Paper elevation={0}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleLogout}>
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

const mapStateToProps = (state: any) => (
  {
    authUser: state.userManagement.authUser
  }
);
const mapDispatchToProps = (dispatch: any) => ({
  setAuthUser: (payload: any) => dispatch(UserManagementActions.setAuthUser(payload)),
  logout: (history: any) => dispatch(UserManagementActions.logout(history))
})
export default connect(mapStateToProps, mapDispatchToProps)(MenuListComposition);


