import { connect } from 'react-redux';
import { UIActions, WorkgroupActions } from '../../redux/actions';
import { WorkgroupsComponent } from './workgroups.component';

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
  workgroups: state.workgroup.workgroupList ? state.workgroup.workgroupList.result : [],
  totalWorkgroups: state.workgroup.workgroupList ? state.workgroup.workgroupList.total_records : 0,
  globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
  getWorkgroups: (filter: any) => dispatch(WorkgroupActions.getList(filter)),
  reprocessWorkgroup: (payload: any) => dispatch(WorkgroupActions.reprocess(payload)),
  setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIActions.setGlobalRowsPerPage(rowsPerPage)),
});

export const WorkgroupsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkgroupsComponent);