import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { SideBarStyles } from '../../../hooks/styles';
import { Constants } from '../../../constants/Constants';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import TableViewIcon from '@mui/icons-material/TableView';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { RxActions, UIActions } from '../../../redux/actions';

const links: any = {
  'products': {
    navGroup: 'product',
    label: Constants.PAGE_TITLE.PRODUCTS
  },
  'packing-items': {
    navGroup: 'product',
    label: Constants.PAGE_TITLE.PACKING_ITEMS
  },
  'pack-sets': {
    navGroup: 'product',
    label: Constants.PAGE_TITLE.PACK_SETS
  },
  'user-management': {
    navGroup: 'user',
    label: Constants.PAGE_TITLE.USER_MANAGEMENT
  },
  'rx-exceptions': {
    navGroup: 'grouping',
    label: Constants.PAGE_TITLE.RX_EXCEPTIONS
  },
  'shipment-groups': {
    navGroup: 'grouping',
    label: Constants.PAGE_TITLE.SHIPMENT_GROUPS
  },
  'prescriptions': {
    navGroup: 'grouping',
    label: Constants.PAGE_TITLE.PRESCRIPTIONS
  },
  'workgroups': {
    navGroup: 'grouping',
    label: Constants.PAGE_TITLE.WORKGROUPS
  },
  'inventory-report': {
    navGroup: 'reports',
    label: Constants.PAGE_TITLE.INVENTORY_REPORT
  }
}

const SideMenuBar = (props: any) => {

  const [initLabel, setInitLabel] = React.useState<string>('')

  const classes = SideBarStyles()

  const isFirstRender = useRef(true)
  useEffect(() => {
    isFirstRender.current = false
    if (!props.navState) {
      const urlPath = window.location.pathname
      let path = ''
      let label = ''
      const initNavState: any = {
        product: false,
        user: false
      }
      Object.keys(links).some((linkPath: string) => {
        if (urlPath.includes(linkPath)) {
          path = '/' + linkPath
          label = links[linkPath].label
          initNavState[links[linkPath].navGroup] = true
          return true
        }
        return false
      })
      setInitLabel(label);
      navigateMenu(label, path)
      props.dispatch(UIActions.setNavState(initNavState))
    }
  }, [])

  const navigateMenu = (title: string, url: string) => {
    props.dispatch(UIActions.setNavigationState({
      title: title,
      url: url
    }));
  }

  const generateMenuActiveClass = (menuTitle: string) => {
    if (!props.activeMenu && initLabel && initLabel === menuTitle) {
      return classes.menuItemActive
    }
    return (props.activeMenu && props.activeMenu.title && props.activeMenu.title === menuTitle) ? classes.menuItemActive : classes.menuItem;
  }

  const getSubNavLink = (key: string, path: string, label: string, icon?: any) => (
    <div key={key}>
      <Link className={classes.link} to={path} onClick={() => navigateMenu(label, path)}>
        <ListItem button className={`${generateMenuActiveClass(label)} sidebarNavItem`} style={{ paddingLeft: 60 }}>
          {icon &&
            <ListItemIcon style={{ minWidth: 40 }}>
              {icon}
            </ListItemIcon>
          }
          {key === 'rx-exceptions' ?
            <Badge badgeContent={props.rxExceptionCount} color='error' overlap="rectangular" >
              <ListItemText primary={label} className={classes.menuItemText} />
            </Badge>
          :
            <ListItemText primary={label} className={classes.menuItemText} />
          }
        </ListItem>
      </Link>
    </div>
  )

  const handleExpand = (navGroup: string) => {
    const newState = props.open ? !props.navState[navGroup] : true
    props.dispatch(UIActions.setNavState({ ...props.navState, [navGroup]: newState }));
  }

  React.useEffect(() => {
    props.dispatch(RxActions.getRxExceptionsCount())
	})

  return (
    <div style={{
      position: 'relative',
      maxHeight: 'calc(100vh - 110px)',
      height: '100%',
      overflow: 'auto'
    }}>
      <>
        <ListItem button onClick={() => handleExpand('product')}>
          <ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
            <VaccinesIcon />
          </ListItemIcon>
          <ListItemText primary="Manage Products" />
          {props.navState?.product ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={props.open && props.navState?.product} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {getSubNavLink('products', '/products', Constants.PAGE_TITLE.PRODUCTS)}
            {getSubNavLink('packingItems', '/packing-items', Constants.PAGE_TITLE.PACKING_ITEMS)}
            {getSubNavLink('packSets', '/pack-sets', Constants.PAGE_TITLE.PACK_SETS)}
          </List>
        </Collapse>
        <ListItem button onClick={() => handleExpand('grouping')}>
          <ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
            <TableViewIcon />
          </ListItemIcon>
          <ListItemText primary="Grouping" />
          {props.navState?.grouping ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={props.open && props.navState?.grouping} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {getSubNavLink('prescriptions', '/prescriptions', Constants.PAGE_TITLE.PRESCRIPTIONS)}
            {getSubNavLink('rx-exceptions', '/rx-exceptions', Constants.PAGE_TITLE.RX_EXCEPTIONS)}
            {getSubNavLink('shipment-groups', '/shipment-groups', Constants.PAGE_TITLE.SHIPMENT_GROUPS)}
            {getSubNavLink('workgroups', '/workgroups', Constants.PAGE_TITLE.WORKGROUPS)}
          </List>
        </Collapse>
        <ListItem button onClick={() => handleExpand('reports')}>
          <ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
          {props.navState?.reports ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={props.open && props.navState?.reports} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {getSubNavLink('inventory-report', '/inventory-report', Constants.PAGE_TITLE.INVENTORY_REPORT)}
          </List>
        </Collapse>
        <Link className={classes.link} to={'/user-management'} onClick={() => navigateMenu(Constants.PAGE_TITLE.USER_MANAGEMENT, '/user-management')}>
          <ListItem button className={`${generateMenuActiveClass(Constants.PAGE_TITLE.USER_MANAGEMENT)} sidebarNavItem`}>
            <ListItemIcon style={{ minWidth: 40 }} onClick={() => props.setOpen(true)}>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Users" />
          </ListItem>
        </Link>
      </>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
  userNav: true,
  navState: state.ui.navState,
  activeMenu: state.ui.menuState,
  rxExceptionCount: state.rx.rxExceptionsCount || 0,
});
export default connect(mapStateToProps)(SideMenuBar);