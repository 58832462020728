import React from 'react';
import { connect } from 'react-redux';
import { ProductActions } from '../../redux/actions';
import { Button, Grid, FormControl, TextField, Chip } from '@material-ui/core';
import { getModalClasses, CommonStyles, getModalStyle } from '../../hooks/styles';
import Loading from '../../components/loading';
import { Autocomplete } from '@mui/material';

interface PackSetFormProps {
  packSet: any
  packingItems: any
  preloadedProducts: any[]
  getPackingItems: () => void
  getPackSets: () => void
  createPackSet: (payload: any) => void
  updatePackSet: (payload: any) => void
  closeModal: () => void
}

const PackSetFormModal: React.FC<PackSetFormProps> = (props) => {
  const classes = CommonStyles();
  const modalClasses = getModalClasses();
  const [modalStyle] = React.useState(getModalStyle());

  const getInitInputs = () => ({
    id: props.packSet?.id || '',
    productId: props.packSet?.productId || '',
    minTemp: props.packSet?.minTemp ?? '',
    maxTemp: props.packSet?.maxTemp ?? '',
    volUsage: props.packSet?.volUsage ?? '',
    instructions: props.packSet?.instructions || '',
    packingItems: props.packSet?.packSetItems.map((pItem: any) => ({id: pItem.packingItemId, qty: pItem.qty, uom: pItem.packingItemDetails?.uom || ''})) || []
  })

  const [inputs, setInputs] = React.useState<any>(getInitInputs())
  const [formErrors, setFormErrors] = React.useState<any>({})

  const [selectedPackSetItem, setSelectedPackSetItem] = React.useState<any>(null)
  const [packingItemQty, setPackingItemQty] = React.useState<number | null>(1)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [submitError, setSubmitError] = React.useState<string>('')

  const [packingItemOptions, setPackingItemOptions] = React.useState<any[]>([])

  const handleCancel = () => {
    props.closeModal();
  }

  const handleSetInputs = (key: string, value: any) => {
    setInputs({ ...inputs, [key]: value })
    setFormErrors({ ...formErrors, [key]: null })
    setSubmitError('')
  }

  const formatString = (str: string) => {
    let formatted = str[0].toUpperCase() + str.slice(1);
    return formatted.replace(/([A-Z])/g, ' $1').trim()
  }

  const handleSave = () => {
    const errors: any = {}
    for (let [key, value] of Object.entries(inputs)) {
      if (value === '') {
        errors[key] = `${formatString(key)} is required`
      }
      if (key === 'packingItems' && inputs['packingItems'].length === 0) {
        console.log('PACKING ITEM ERROR')
        errors[key] = 'At least one packing item is required'
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }



    setIsLoading(true)

    const onSuccess = () => {
      setIsLoading(false)
      props.getPackSets()
      props.closeModal()
    }

    const onError = () => {
      setIsLoading(false)
      setSubmitError('Failed to save pack set')
    }

    const requestBody = props.packSet ? getEditRequestBody() : getAddRequestBody()

    const payload = {
      requestBody: requestBody,
      success: onSuccess,
      error: onError
    }

    const submitFn = props.packSet ? props.updatePackSet : props.createPackSet

    submitFn(payload)
  }

  const getAddRequestBody = () => ({
    packSet: {
      id: inputs.id,
      productId: inputs.productId,
      minTemp: inputs.minTemp,
      maxTemp: inputs.maxTemp,
      volUsage: inputs.volUsage,
      instructions: inputs.instructions,
      active: true
  },
    packSetItems: inputs.packingItems.map((pItem: any) => ({ packingItemId: pItem.id, qty: pItem.qty })),
  })

  const getEditRequestBody = () => ({
    packSet: {
      id: props.packSet.id,
      productId: inputs.productId,
      minTemp: inputs.minTemp,
      maxTemp: inputs.maxTemp,
      volUsage: inputs.volUsage,
      instructions: inputs.instructions,
    },
    packSetItems: inputs.packingItems.map((pItem: any) => ({ packingItemId: pItem.id, qty: pItem.qty })),
  })

  React.useEffect(() => {
    props.getPackingItems()
  }, [])

  React.useEffect(() => {
    setPackingItemOptions(props.packingItems.filter((packingItem: any) => {
      if(inputs.packingItems.length === 0){
        return packingItem.packout === true && packingItem.supplies === false
      }
      const isInPackingItemsList = inputs.packingItems.some((item: any) => item.id === packingItem.id)
      return packingItem.packout === true && packingItem.supplies === true && !isInPackingItemsList
    }))
  }, [props.packingItems, inputs])

  return (
    <div style={modalStyle} className={modalClasses.paper}>
      <div>
        <h3 id="simple-modal-title">{props.packSet ? 'Edit Pack Set' : 'Add New Pack Set'}</h3>
        <Grid container>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                label="Set Id"
                variant="outlined"
                value={inputs.id}
                error={formErrors.id ? true : false}
                helperText={formErrors.id ? formErrors.setId : ''}
                onChange={(event) => { handleSetInputs('id', event.target.value) }}
                disabled={props.packSet ? true : false}
                required={props.packSet ? false : true}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={props.preloadedProducts}
                  getOptionLabel={(option: any) => `${option.description}(${option.id})`}
                  value={inputs.productId ? props.preloadedProducts.find((product: any) => product.id === inputs.productId) : null}
                  disabled={props.packSet ? true : false}
                  onChange={(event, newValue) => {
                    handleSetInputs('productId', newValue?.id || null);
                  }}
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      variant="outlined" 
                      label="Product" 
                      error={formErrors.productId ? true : false} 
                      helperText={formErrors.productId || ''} 
                      required={props.packSet ? false : true}
                    />
                  }
                />
              </FormControl>
            <FormControl className={classes.formControlSmall}>
              <TextField
                label={<>Min Temp &#176;F</>}
                variant="outlined"
                type="number"
                value={inputs.minTemp}
                error={formErrors.minTemp ? true : false}
                helperText={formErrors.minTemp ? formErrors.minTemp : ''}
                onChange={(event) => { handleSetInputs('minTemp', event.target.value) }}
                required
              />
            </FormControl>
            <FormControl className={classes.formControlSmall}>
              <TextField
                label={<>Max Temp &#176;F</>}
                variant="outlined"
                type="number"
                value={inputs.maxTemp}
                error={formErrors.maxTemp ? true : false}
                helperText={formErrors.maxTemp ? formErrors.maxTemp : ''}
                onChange={(event) => { handleSetInputs('maxTemp', event.target.value) }}
                required
              />
            </FormControl>
            <FormControl className={classes.formControlSmall}>
              <TextField
                label="Vol Usage %"
                variant="outlined"
                type="number"
                value={inputs.volUsage}
                error={formErrors.volUsage ? true : false}
                helperText={formErrors.volUsage ? formErrors.volUsage : ''}
                onChange={(event) => { handleSetInputs('volUsage', event.target.value) }}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
                <FormControl className={classes.formControlLarge}>
                    <TextField
                        label="Instructions"
                        variant="outlined"
                        value={inputs.instructions}
                        error={formErrors.instructions ? true : false}
                        helperText={formErrors.instructions ? formErrors.instructions : ''}
                        onChange={(event) => { handleSetInputs('instructions', event.target.value) }}
                        required
                    />
                </FormControl>
            </Grid>
          <Grid item xs={12}>
            <h4>Pack Set Supplies</h4>
            <FormControl className={classes.formControlMedium}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={packingItemOptions}
                getOptionLabel={(option: any) => `${option.description}(${option.id})`}
                value={selectedPackSetItem || null}
                onChange={(event, newValue) => {
                  setSelectedPackSetItem(newValue)
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Packing Items" required />}
              />
            </FormControl>
            {selectedPackSetItem && <FormControl className={classes.formControlSmall}>
              <TextField
                label={selectedPackSetItem.uom}
                variant="outlined"
                type="number"
                value={packingItemQty || ''}
                onChange={(event) => { setPackingItemQty(parseInt(event.target.value) || null) }}
                disabled={selectedPackSetItem.supplies ? false : true}
                required
              />
            </FormControl>}
            {selectedPackSetItem && packingItemQty && <FormControl>
              <Button
                onClick={() => {
                  if (inputs.packingItems.find((pItem: any) => pItem.id === selectedPackSetItem.id)) {
                    return
                  }
                  let inputPackagingItems = inputs.packingItems
                  inputPackagingItems.push({ ...selectedPackSetItem, qty: packingItemQty })
                  handleSetInputs('packingItems', inputPackagingItems)
                  setSelectedPackSetItem(null)
                  setPackingItemQty(1)
                }}
                type="button"
                style={{ marginTop: 15 }}
                variant="contained"
                color="primary"
                className={classes.viewAllBtn}
              >
                ADD
              </Button>
            </FormControl>}
          </Grid>
          {formErrors.packingItems &&
            <Grid item xs={12}>
              <span className={classes.errorMsg}>{formErrors.packingItems}</span>
            </Grid>
          }
          <Grid item xs={12}>
            {inputs.packingItems.map((pItem: any, index: number) => (
              <Chip
                key={index}
                style={{ margin: 5 }}
                label={<span>{pItem.id}: {pItem.qty} {pItem.uom}</span>}
                onDelete={() => {
                  //If you delete the box then delete all supplies with it
                  if(pItem.packout === true && pItem.supplies === false){
                    handleSetInputs('packingItems', [])
                    setPackingItemQty(1)
                    setSelectedPackSetItem(null)
                    return
                  }
                  let inputPackingItems = inputs.packingItems
                  inputPackingItems.splice(index, 1)
                  handleSetInputs('packingItems', inputPackingItems)
                }}
              />
            ))}
          </Grid>
          {submitError && <p className={classes.errorMsg}>{submitError}</p>}
          {isLoading ?
            <Loading message="" />
            :
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <Button
                  onClick={handleSave}
                  type="button"
                  style={{ marginTop: 12 }}
                  className={classes.searchButton}
                  variant="contained"
                  color="primary"
                >
                  SAVE
                </Button>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Button
                  onClick={handleCancel}
                  type="button"
                  style={{ marginTop: 12, marginLeft: 0 }}
                  className={classes.cancelBtn}
                  variant="contained"
                  color="default"
                >
                  CANCEL
                </Button>
              </FormControl>
            </Grid>}
        </Grid>
      </div>
    </div >
  )
}

const mapStateToProps = (state: any) => ({
  packingItems: state.product.packingItems ? state.product.packingItems.result : [],
  preloadedProducts: state.product.preloadedProducts ? state.product.preloadedProducts.result : [],
});

const mapDispatchToProps = (dispatch: any) => ({
  createPackSet: (payload: any) => dispatch(ProductActions.createPackSet(payload)),
  updatePackSet: (payload: any) => dispatch(ProductActions.updatePackSet(payload)),
  getPackingItems: () => dispatch(ProductActions.getPackingItems({filter: {}})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackSetFormModal);
