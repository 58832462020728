import { call, put } from "redux-saga/effects";
import { RxActions, UIActions } from "../actions";
import { applyExportOptions, downloadCsvFile } from "../lib/dataExportUtils";
import { RxService } from "../services/rx.service";

export function* getAlertTransactions(action: ReturnType<typeof RxActions.getAlertTransactions>) {
    try {
        yield put(UIActions.showLoader(true));
        const { data } = yield call(RxService.getInstance().getAlertTransactions, action.payload.filter);
        
        if (action.payload.exportOptions) {
            const dataWithOptions = applyExportOptions(action.payload.exportOptions, data.result)
            downloadCsvFile(action.payload.exportOptions.fileName, dataWithOptions)
            yield put(UIActions.showLoader(false));
            return;
        }

        if(data.result){
            yield put(RxActions.setAlertTransactions(data));
        }
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (!action.payload.exportOptions) {
            yield put(RxActions.setAlertTransactions([]));
        }
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}

export function* getRxExceptionsCount(action: ReturnType<typeof RxActions.getRxExceptionsCount>) {
    try {
        const { data } = yield call(RxService.getInstance().getRxExceptionsCount);
        yield put(RxActions.setRxExceptionsCount(data.count));
    } catch (err) {
        yield put(RxActions.setRxExceptionsCount(null));
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}

export function* getRxList(action: ReturnType<typeof RxActions.getRxList>) {
    try {
        yield put(UIActions.showLoader(true));
        const { data } = yield call(RxService.getInstance().getRxList, action.payload.filter);
        
        if (action.payload.exportOptions) {
            const dataWithOptions = applyExportOptions(action.payload.exportOptions, data.result)
            downloadCsvFile(action.payload.exportOptions.fileName, dataWithOptions)
            yield put(UIActions.showLoader(false));
            return;
        }
        
        if(data.result){
            console.log(data);
            
            yield put(RxActions.setRxList(data));
        }
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (!action.payload.exportOptions) {
            yield put(RxActions.setRxList([]));
        }
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}

export function* updateRx(action: ReturnType<typeof RxActions.updateRx>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(RxService.getInstance().updateRx, action.payload.requestBody);
        yield call(action.payload.success)
        yield put(UIActions.showLoader(false));
    } catch (err) {
        console.log('Update rx saga error', err)
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            action.payload.error()
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}

export function* resolveRx(action: ReturnType<typeof RxActions.resolveRx>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(RxService.getInstance().resolveRx, action.payload);
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}

export function* cancelRx(action: ReturnType<typeof RxActions.cancelRx>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(RxService.getInstance().cancelRx, action.payload);
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}

export function* importReprocess(action: ReturnType<typeof RxActions.importReprocess>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(RxService.getInstance().importReprocess);
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (err instanceof Error) {
            // const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));  
            }
        }
    }
}
