import createReducer from './createReducer';
import { WorkgroupActions } from '../actions'
import { WORKGROUP } from '../constants/actions';


export const workgroup = createReducer({}, {
  [WORKGROUP.SET_LIST](state = {}, action: ReturnType<typeof WorkgroupActions.setList>) {
    return { ...state, workgroupList: action.payload }
  },
  
});