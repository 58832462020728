import React from "react";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Checkbox, Chip, ClickAwayListener, Grid, Popper, TextField } from "@material-ui/core";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Autocomplete } from "@material-ui/lab";
import { CommonStyles } from "../../hooks/styles";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { DesktopDateRangePopup } from "../dateRangePopup";
import moment from "moment";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface SearchFilterProps {
  config: any[]
  filters: any
  onFilterChange: (value: any) => void
  handleSearch: () => void
}

const filterIcons: any = {
  text: <TextFieldsIcon />,
  dateRange: <DateRangeIcon />,
  date: <DateRangeIcon />,
  select: <ExpandMoreIcon />,
  autoComplete: <ExpandMoreIcon />
}

const filterInputs: any = {
  text: (inputProps: any) => <TextField
    autoFocus
    variant="outlined"
    placeholder={inputProps.label}
    onChange={(e: any) => inputProps.setValue(e.target.value)}
  />,
  number: (inputProps: any) => <TextField
    autoFocus
    variant="outlined"
    placeholder={inputProps.label}
    type="number"
    onChange={(e: any) => {
      let value = e.target.value
      if (inputProps.nonNegative && value <= 0) {
        value = 0
      }
      e.target.value = value
      inputProps.setValue(value)
    }}
  />,
  autoComplete: (inputProps: any) => <Autocomplete
    onChange={(e: any, newValue: any) => {
      inputProps.setValue(newValue?.id || null)
      inputProps.closePopper()
    }}
    style={{ width: 400 }}
    id="combo-box-demo"
    options={inputProps.selectOptions || []}
    getOptionLabel={(option: any) => `${option.name || option.description || option.id}(${option.id})`}
    renderInput={(params: any) => <TextField {...params} placeholder={inputProps.label} autoFocus variant="outlined" style={{ backgroundColor: "white" }} />}
  />,
  multiAutoComplete: (inputProps: any) => <Autocomplete
    multiple
    disableCloseOnSelect
    onChange={(e: any, newValue: any) => {
      inputProps.setValue(newValue || null)
    }}
    style={{ minWidth: 400 }}
    id="combo-box-demo"
    options={inputProps.selectOptions || []}
    getOptionLabel={(option: any) => `${option.name || option.description || option.id}(${option.id})`}
    // defaultValue={inputProps.selectOptions.filter((option: any) => inputProps.defaultValue.includes(option.id))}
    renderOption={(option, {selected}) => (
      <React.Fragment>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {`${option.name || option.description || option.id}(${option.id})`}
      </React.Fragment>
    )}
    renderInput={(params: any) => <TextField {...params} placeholder={inputProps.label} autoFocus variant="outlined" style={{ backgroundColor: "white" }} />}
  />,
  date: (inputProps: any) => <LocalizationProvider dateAdapter={AdapterMoment}>
    <DatePicker
      label={''}
      value={inputProps.filters[inputProps.name] || ''}
      inputFormat="YYYY-MM-DD"
      mask="____-__-__"
      onChange={(date) => {
        inputProps.setValue((date ? date.format('YYYY-MM-DD') : null))
      }}
      renderInput={(params: any) => <TextField {...params} autoFocus variant="outlined" />}
    />
  </LocalizationProvider>,
  dateRange: (inputProps: any) => <DesktopDateRangePopup
    onSelect={(dates: any) => {
      inputProps.setValue(dates);
    } } 
    setOpen={() => {}} 
    dates={inputProps.filters[inputProps.name] || ''}  
  />

}


const SearchFilter: React.FC<SearchFilterProps> = (props) => {
  const classes = CommonStyles();
  const [config] = React.useState<any[]>(props.config)
  const [filters, setFilters] = React.useState<any>(props.filters)
  const [popperOpen, setPopperOpen] = React.useState(false)
  const [popperAnchor, setPopperAnchor] = React.useState<any>(null)
  const [currentFilter, setCurrentFilter] = React.useState<any>(null)

  React.useEffect(() => {
    setFilters(props.filters)
  }, [props.filters])

  const renderFilterChip = (config: any) => {
    const icon = filterIcons['select'] // Possibly use different icons for different filter types: filterIcons[config.type]
    let label = ''
    if(config.type === 'dateRange'){
      const dates = filters[config.name]//().props.dates
      const startDateString = dates.startDate ? moment(dates.startDate).local().format("YYYY-MM-DD") : dates
      const endDateString = dates.endDate ? moment(dates.endDate).local().format("YYYY-MM-DD") : ""
      label = `${config.label}: ${endDateString ? startDateString + " - " + endDateString : startDateString}`
    }
    else if(Array.isArray(filters[config.name])){
      label = `${config.label}: ${filters[config.name].map((filter: any) => filter.id)}`
    }
    else{
      label = `${config.label}${filters[config.name] ? `: ${filters[config.name]}` : ``}`
    }
    return (
      <Grid key={config.name} item xs>
        <Chip
          icon={filters[config.name] ? null : icon}
          label={label}
          onClick={(e: any) => {
            setPopperOpen(true)
            setPopperAnchor(e.currentTarget)
            setCurrentFilter(config)
          }}
          onDelete={filters[config.name] ? () => { props.onFilterChange({ ...filters, [config.name]: undefined }) } : undefined}
        />
      </Grid>
    )
  }

  const renderFilterInput = () => {
    const inputProps = {
      ...currentFilter,
      filters: filters,
      setValue: (value: any) => props.onFilterChange({ ...filters, [currentFilter.name]: value }),
      selectOptions: currentFilter.selectOptions || null,
      defaultValue: currentFilter.defaultValue ?? '',
      closePopper: () => setPopperOpen(false)
    }
    return filterInputs[currentFilter.type](inputProps)
  }

  return (
    <>
      {popperOpen &&
        <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
          <Popper open={popperOpen} anchorEl={popperAnchor} placement="bottom-start">
            {renderFilterInput()}
          </Popper>
        </ClickAwayListener>
      }
      <Grid container spacing={2}>
        {config.map((filterConfig: any) => (
          renderFilterChip(filterConfig)
        ))}
        <Grid item xs>
          <Button
            className={classes.viewAllBtn}
            variant="contained"
            color="secondary"
            onClick={() => {
              props.handleSearch()
            }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default SearchFilter
