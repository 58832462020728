import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, FormControl } from '@material-ui/core';
import { CommonStyles, getModalClasses, getModalStyle } from '../../hooks/styles';
import Loading from '../loading';

interface DeleteModalProps {
    deleteId: any
    title: any
    message: any
    deleteLabel?: string
    cancelLabel?: string
    onDelete: (payload: any) => void
    onSuccess: () => void
    closeModal: () => void
}

const DeleteModal: React.FC<DeleteModalProps> = (props) => {
    const classes = CommonStyles();
    const modalClasses = getModalClasses();
    const [modalStyle] = React.useState(getModalStyle());

    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const handleCancel = () => {
        props.closeModal();
    }

    const handleDelete = () => {
        setIsLoading(true)

        const onSuccess = () => {
            setIsLoading(false)
            props.onSuccess()
            props.closeModal()
        }

        const onError = () => {
            setIsLoading(false)
        }

        const payload = {
            deleteId: props.deleteId,
            success: onSuccess,
            error: onError
        }

        props.onDelete(payload)
    }

    return (
        <div style={modalStyle} className={modalClasses.paper}>
        <div>
            <h3 id="simple-modal-title">{props.title}</h3>
            <p>{props.message}</p>
            <Grid container>
                {isLoading ?
                    <Loading message="" />
                    :
                    <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                        <Button
                            onClick={handleDelete}
                            type="button"
                            style={{ marginTop: 12 }}
                            className={classes.searchButton}
                            variant="contained"
                            color="primary"
                        > {props.deleteLabel ?? 'DELETE'} </Button>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <Button
                            onClick={handleCancel}
                            type="button"
                            style={{ marginTop: 12, marginLeft: 0 }}
                            className={classes.cancelBtn}
                            variant="contained"
                            color="default"
                        > {props.cancelLabel ?? 'CANCEL'} </Button>
                    </FormControl>
                </Grid>}
            </Grid>
        </div>
        </div >
    )
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteModal);
