import { connect } from 'react-redux';
import { ReportActions, UIActions } from '../../redux/actions';
import { InventoryReportComponent } from './inventory-report.component';

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
  inventoryReport: state.report.inventoryReport ? state.report.inventoryReport.result : [],
  inventoryReportRows: state.report.inventoryReport ? state.report.inventoryReport.total_records : 0,
  globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
  getInventoryReport: (payload: any) => dispatch(ReportActions.getInventoryReport(payload)),
  setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIActions.setGlobalRowsPerPage(rowsPerPage)),
});

export const InventoryReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryReportComponent);