import { makeStyles } from '@material-ui/core/styles';
import { globalColors } from '../../hooks/styles/muiTheme';

export const InventoryReportsStyles = makeStyles((theme) => ({

  root: {
    '& > .fa': {
      margin: theme.spacing(2),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },

  formControl: {
    marginBottom: '12px!important',
    marginLeft: 0,
    marginRight: '20px!important',


  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  reportSearchBox: {
    padding: 9,
    border: '1 solid #f2f2f2!important',
    marginBottom: '10px!important',
    background: '#fff',
  },
  expandMoreIcon: {
    width: 10
  },
  exportButtons: {
    marginRight: 10,
    background: `${globalColors.NF_BLUE} !important`
  },
  exportButtonsBox: {
    marginBottom: '30px!important'
  },
  formControlBlank: {
    minWidth: 0,
  },
  datebox: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: 10,
    marginLeft: 10,
    border: '1 solid #c7c7c7',
    borderRadius: 5
  },
  tab: {
    minWidth: 300,
    textTransform: 'none'
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  transactionLogsdate: {
    width: 140,
    minWidth: 100
  },
  indicator: {
    backgroundColor: globalColors.NF_BLUE
  },
  dateRange: {
    width: '75%',
    position: 'fixed',
    zIndex: 9999
  },
  searchButton: {
    margin: '12px 0 0 10px',
    fontSize: '10px!important',
  },
  buttonFormControl: {
    marginBottom: '12px!important',
    marginLeft: 0,
    marginRight: '16px!important',
    minWidth: '60px!important'
  },
  addMoreButton: {
    color: `${globalColors.NF_BLUE} !important`,
    '&:hover': {
      background: 'none!important'
    },
    width: '40px!important',
    fontSize: '10px!important',
    marginTop: '10px!important'
  },
  dateQuickSelect: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  searchWrap: {
    padding: 9
  },
  groupByWrap: {
   position: 'absolute',
   top: 25,
   right: 160,
   display: 'flex',
   flexDirection: 'row',
   zIndex: 500,
   alignItems: 'center',

   [theme.breakpoints.down('md')]: {
      top: -25,
      left: 0,
      right: 'unset'
    },
  },
  groupByWrapGrouped: {
    right: -20,
    top: 0
  },
  groupByHeading: {
    margin: '0 8px 0 0'
  },
  groupByLinks: {
    margin: '0 20px 0 0',
  },
  groupByButtons: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  groupByOption: {
    backgroundColor: 'inherit !important'
  }
}), { index: 1 });
export default InventoryReportsStyles;




