import Api from "../lib/api";
import { GROUPS } from "../constants/api.url";

export class GroupService {

  private static instance: GroupService;
  private constructor() { }

  public static getInstance(): GroupService {
    if (!GroupService.instance) {
      GroupService.instance = new GroupService();
    }
    return GroupService.instance;
  }

  public getGroups = async (params: any) : Promise<any> => {
    let queryString: any[] = [{key: 'filter', value: JSON.stringify(params.filter)}];
    params.rxCount && queryString.push({key: 'rxCount', value: params.rxCount})
    params.sortRxCount && queryString.push({key: 'sortRxCount', value: params.sortRxCount})
    return await Api.get(GROUPS.URL, queryString)
  }

  public getGroupDetails = async (id: any) : Promise<any> => {
    return await Api.get(GROUPS.URL + '/' + id + '/detail')
  }

  public updateGroupServiceType = async (payload: any) : Promise<any> => {
    const params = {carrier: payload.carrier, serviceType: payload.serviceType}
    return await Api.patch(GROUPS.URL + '/' + payload.id + '/serviceType', params)
  }

}
