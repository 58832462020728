import * as React from 'react';
import { FormControl, Button, Modal, Tooltip } from '@material-ui/core';
import { CommonStyles } from '../../hooks/styles';
import dataList from '../../constants/dataList';
import MUIDataTable from "mui-datatables";
import Loading from '../../components/loading';
import UserFormModal from './user-form-modal.component';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteForever } from '@material-ui/icons';
import DeleteModal from '../../components/deleteModal';
import moment from 'moment';

interface UserManagementProps {
	authUser?: any
	users: any[]
	totalUsers: any
	globalRowsPerPage: any
	getUsers: (filter: any) => void
	inviteUser: (payload: any) => void
	updateUser: (payload: any) => void
	deleteUser: (payload: any) => void
	setGlobalRowsPerPage: (numRows: any) => void
}

export const UserManagementComponent: React.FC<UserManagementProps> = (props) => {
	const classes = CommonStyles();
	const firstRender = React.useRef(false);
	const [modalOpen, setModalOpen] = React.useState(false)
	const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
	const [deleteId, setDeleteId] = React.useState(null as any)
	const [deleteEmail, setDeleteEmail] = React.useState(null as any)
	const [userToEdit, setUserToEdit] = React.useState(null as any)
	const [sortOrder, setSortOrder] = React.useState(null as any)
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [pagination, setPageState] = React.useState({
		offset: 0,
		limit: props.globalRowsPerPage,
		page: 0
	});

	const renderIcons: any = (tableMeta: any) => {
		return (
			<div style={{ color: 'rgba(0, 0, 0, 0.54)', float: 'right' }}>
				<Tooltip title="Edit" placement="bottom-start">
					<EditIcon
						onClick={(e) => {
							triggerModal(props.users[tableMeta.rowIndex]);
						}}
						style={{ marginRight: 10, cursor: 'pointer' }}
					/>
				</Tooltip>
				<Tooltip title="Delete" placement="bottom-start">
					<DeleteForever
						onClick={(e) => {
							triggerDeleteModal(props.users[tableMeta.rowIndex])
						}}
						style={{ cursor: 'pointer' }}
					/>
				</Tooltip>
			</div>
		)
	}

	const columnData = [
		{
			label: "First Name",
			name: "firstName",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Last Name",
			name: "lastName",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Email",
			name: "email",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Last Login",
			name: "lastLogin",
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any, tableMeta: any) => { 
				return moment(value).format('YYYY-MM-DD hh:mm')
			}
		},
		{
			label: "Role",
			name: "role",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "",
			name: "",
			change: false,
			viewColumns: false,
			display: true,
			sort: false,
			customBodyRender: (value: any, tableMeta: any) => { return renderIcons(tableMeta) }
		},
	]

	const getTableColumns = () => {
		return columnData.map((column: any, index: number) => {
			return {
				label: column.label,
				name: column.name,
				options: {
					filter: true,
					display: tableStatePersist ? tableStatePersist.columns[index].display : column.display,
					change: column.change,
					sort: column.sort ?? true,
					viewColumns: column.viewColumns,
					...(column.customBodyRender) && { customBodyRender: column.customBodyRender },
				}
			}
		})
	}

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			filterUsers()
		}
		else {
			firstRender.current = false;
		}
	}, [pagination, sortOrder])

	const getFilter = (reset?: boolean) => {
		const filter: any = {
			fields: {
				source: true,
				productId: true,
				productName: true,
				ndc: true,
				doseType: true,
				packUom: true,
				packQty: true,
				lumQty: true,
				productGroup: true,
				mbrxProductId: true,
				mbrxProductIdExt: true,
				value: true,
				active: true
			},
			limit: props.globalRowsPerPage,
			offset: pagination.offset,
			order: sortOrder ? [sortOrder] : ['productId'],
			where: { source: 'FFF' } //filters ultimately end up here 
		}

		// const filterValues = props.selectedFilterValues || {};

		// if (filterValues['PRODUCT_NAME']) {
		// const productId = filterValues['PRODUCT_NAME'].productItemId;
		// filter.where.or = [{ productId: productId }, { mbrxProductId: productId }];
		// }

		// if (filterValues['DOSE_TYPE']) {
		// filter.where.doseType = filterValues['DOSE_TYPE'];
		// }

		return filter
	}

	const filterUsers = (reset?: boolean) => {
		props.getUsers(getFilter(reset))
	}

	const handleSearch = () => {
		setPageState({
			offset: 0,
			limit: props.globalRowsPerPage,
			page: 0
		})
	}

	const showLoadingMsg = () => (
		!props.authUser && props.users.length === 0
	)

	const handleModalClose = () => {
		setModalOpen(false);
		setUserToEdit(null)
	};

	const handleDeleteModalClose = () => {
		setDeleteModalOpen(false);
		setDeleteId(null)
		setDeleteEmail(null)
	};

	const triggerModal = (user: any) => {
		setUserToEdit(user);
		setModalOpen(true);
	}

	const triggerDeleteModal = (user: any) => {
		setDeleteId(user.id);
		setDeleteEmail(user.email)
		setDeleteModalOpen(true);
	}

	return (<div>
		{showLoadingMsg() ? <div><Loading message="" /></div> : <div>
			{/* <div className={classes.searchWrap}>

			</div> */}

			<div className={classes.pageActionsWrap}>
				<FormControl className={classes.formControlSmall}>
					<Button
						className={classes.viewAllBtn}
						variant="contained"
						color="secondary"
						onClick={() => {
							triggerModal(null)
						}}
					>
						Add User
					</Button>
				</FormControl>
			</div>
			<div>
				<MUIDataTable
					data={props.users}
					columns={getTableColumns()}
					options={{
						rowsPerPage: props.globalRowsPerPage,
						rowsPerPageOptions: dataList.PageLimitOptions,
						onChangeRowsPerPage: (numberOfRows: number) => {
							setPageState({ offset: 0, limit: numberOfRows, page: 0 });
							props.setGlobalRowsPerPage(numberOfRows);
						},
						filterType: "dropdown",
						responsive: "simple",
						filter: false,
						search: false,
						download: true,
						print: true,
						selectableRows: 'none',
						serverSide: false,
						page: pagination.page,
						count: props.totalUsers,
						onColumnSortChange: (changedColumn: string, direction: string) => {
							setSortOrder(`${changedColumn} ${direction.toUpperCase()}`)
						},
						onTableChange: (tableAction: any, tableState: any) => {
							switch (tableAction) {
								case 'changePage':
									if (tableState.page > pagination.page) {
										setPageState({ offset: pagination.offset + props.globalRowsPerPage, limit: props.globalRowsPerPage, page: tableState.page });
									}
									else if (tableState.page < pagination.page) {
										setPageState({ offset: pagination.offset - props.globalRowsPerPage, limit: props.globalRowsPerPage, page: tableState.page });
									}
									break;
								case 'viewColumnsChange':
									setTableStatePersist(tableState);
									break;
							}
						},
					}}
				/>
			</div>
			{/* :
				<EmptyContent message="Change the filter criteria above to view product information." />
			} */}
			<Modal
				open={modalOpen}
				onClose={(e: any, reason: string) => {
					if (reason === 'backdropClick') return
					handleModalClose()
				}}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					<UserFormModal getUsers={filterUsers} user={userToEdit} inviteUser={props.inviteUser} updateUser={props.updateUser} closeModal={handleModalClose} />
				</div>
			</Modal>
			<Modal
				open={deleteModalOpen}
				onClose={handleDeleteModalClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					<DeleteModal
						deleteId={deleteId}
						title={'Delete User'}
						message={`Are you sure you want to delete the user with email ${deleteEmail}`}
						onDelete={props.deleteUser}
						onSuccess={handleSearch}
						closeModal={handleDeleteModalClose} />
				</div>
			</Modal>
		</div>}
	</div>)
}