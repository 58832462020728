import React from 'react';
import { connect } from 'react-redux';
import { ProductActions } from '../../redux/actions';
import { Button, Grid, FormControl, TextField, MenuItem } from '@material-ui/core';
import { getModalClasses, CommonStyles, getModalStyle } from '../../hooks/styles';
import Loading from '../../components/loading';
import dataList from '../../constants/dataList';

interface PackingItemsFormProps {
    packingItem: any
    getPackingItems: () => void
    createPackingItem: (payload: any) => void
    updatePackingItem: (payload: any) => void
    closeModal: () => void
}

const PackingItemFormModal: React.FC<PackingItemsFormProps> = (props) => {
  const classes = CommonStyles();
  const modalClasses = getModalClasses();
  const [modalStyle] = React.useState(getModalStyle());

  const getInitInputs = () => ({
    id: props.packingItem?.id || '',
    description: props.packingItem?.description || '',
    uom: props.packingItem?.uom || '',
    priority: props.packingItem?.priority || '',
    width: props.packingItem?.width || '',
    height: props.packingItem?.height || '',
    length: props.packingItem?.length || '',
    weight: props.packingItem?.weight || '',
    bagout: props.packingItem?.bagout ?? '',
    packout: props.packingItem?.packout ?? '',
    supplies: props.packingItem?.supplies ?? '',
    innerBox: props.packingItem?.innerBox ?? '',
  })

  const setDefaultProcessType = () => {
    const item = props.packingItem
    if(item.bagout && !item.packout && !item.supplies) return "Bagout"
    if(!item.bagout && item.packout && !item.supplies && !item.innerBox) return "Packout"
    if(!item.bagout && item.packout && item.supplies && !item.innerBox) return "Supply"
    if(!item.bagout && item.packout && item.innerBox) return "Inner Box"
    return "Unknown"
  }

  const [inputs, setInputs] = React.useState<any>(getInitInputs())
  const [processType, setProcessType] = React.useState<string>(props.packingItem ? setDefaultProcessType() : 'UNKNOWN')
  const [formErrors, setFormErrors] = React.useState<any>({})

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [submitError, setSubmitError] = React.useState<string>('')

  const handleCancel = () => {
    props.closeModal();
  }

  const handleSetInputs = (key: string, value: any) => {
    setInputs({ ...inputs, [key]: value })
    setFormErrors({ ...formErrors, [key]: null })
    setSubmitError('')
  }

  const handleSetProcessType = (process: string) => {
    setInputs({ ...inputs, bagout: process === "Bagout", packout: ["Packout", "Supply", "Inner Box"].includes(process), supplies: process === "Supply", innerBox: process === "Inner Box"})
    setFormErrors({ ...formErrors, bagout: null, packout: null, supplies: null})
    setSubmitError('')
    setProcessType(process)
  }

  const formatString = (str: string) => {
    let formatted = str[0].toUpperCase() + str.slice(1);
    return formatted.replace(/([A-Z])/g, ' $1').trim()
  }

  const handleSave = () => {
    let errors: any = {}
    for (let [key, value] of Object.entries(inputs)) {
        if (value === '') {
            errors[key] = `${formatString(key)} is required`
        }
    }

    if (Object.keys(errors).length > 0) {
        setFormErrors(errors)
        return
    }

    setIsLoading(true)

    const onSuccess = () => {
      setIsLoading(false)
      props.getPackingItems()
      props.closeModal()
    }

    const onError = () => {
      setIsLoading(false)
      setSubmitError('Failed to save packing item')
    }

    const requestBody = props.packingItem ? getEditRequestBody() : getAddRequestBody()

    delete requestBody.updatedAt
    delete requestBody.updatedBy
    delete requestBody.storageTypeId

    const payload = {
      requestBody: requestBody,
      success: onSuccess,
      error: onError
    }

    const submitFn = props.packingItem ? props.updatePackingItem : props.createPackingItem

    submitFn(payload)
  }

  const getAddRequestBody = () => ({
    id: inputs.id,
    uom: inputs.uom,
    priority: +inputs.priority,

    width: +inputs.width,
    height: +inputs.height,
    length: +inputs.length,
    weight: +inputs.weight,

    bagout: inputs.bagout,
    packout: inputs.packout,
    supplies: inputs.supplies,
    innerBox: inputs.innerBox,

    description: inputs.description,
})

  const getEditRequestBody = () => ({
    ...props.packingItem,
    ...{
      description: inputs.description,
      uom: inputs.uom,
      priority: +inputs.priority,
      width: +inputs.width,
      height: +inputs.height,
      length: +inputs.length,
      weight: +inputs.weight,
      bagout: inputs.bagout,
      packout: inputs.packout,
      supplies: inputs.supplies,
      innerBox: inputs.innerBox,
    }
  })

  return (
    <div style={modalStyle} className={modalClasses.paper}>
      <div>
        <h3 id="simple-modal-title">{props.packingItem ? 'Edit Packing Item' : 'Add New Packing Item'}</h3>
        <Grid container>
            <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                    <TextField
                        label="ID"
                        variant="outlined"
                        value={inputs.id}
                        error={formErrors.id ? true : false}
                        helperText={formErrors.id ? formErrors.id : ''}
                        onChange={(event) => { handleSetInputs('id', event.target.value) }}
                        disabled={props.packingItem ? true : false}
                        required={props.packingItem ? false : true}
                    />
                </FormControl>
                <FormControl className={classes.formControl}>
                    <TextField
                        label="Unit of Measure"
                        variant="outlined"
                        value={inputs.uom}
                        error={formErrors.uom ? true : false}
                        helperText={formErrors.uom ? formErrors.uom : ''}
                        onChange={(event) => { handleSetInputs('uom', event.target.value) }}
                        required
                    />
                </FormControl>
                <FormControl className={classes.formControl}>
                    <TextField
                        variant="outlined"
                        value={inputs.priority}
                        label="Priority"
                        select
                        required
                        error={formErrors.priority ? true : false}
                        helperText={formErrors.priority ? formErrors.priority : ''}
                        onChange={(event: any) => {
                        handleSetInputs('priority', event.target.value)
                        }}
                    >
                        {dataList.Priority.map((priority: any, index: number) => <MenuItem key={index} value={priority.value}>{priority.label}</MenuItem>)}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl className={classes.formControlSmall}>
                    <TextField
                        label="Width mm"
                        variant="outlined"
                        type="number"
                        value={inputs.width}
                        error={formErrors.width ? true : false}
                        helperText={formErrors.width ? formErrors.width : ''}
                        onChange={(event) => { handleSetInputs('width', event.target.value) }}
                        required
                    />
                </FormControl>
                <FormControl className={classes.formControlSmall}>
                    <TextField
                        label="Height mm"
                        variant="outlined"
                        type="number"
                        value={inputs.height}
                        error={formErrors.height ? true : false}
                        helperText={formErrors.height ? formErrors.height : ''}
                        onChange={(event) => { handleSetInputs('height', event.target.value) }}
                        required
                    />
                </FormControl>
                <FormControl className={classes.formControlSmall}>
                    <TextField
                        label="Length mm"
                        variant="outlined"
                        type="number"
                        value={inputs.length}
                        error={formErrors.length ? true : false}
                        helperText={formErrors.length ? formErrors.length : ''}
                        onChange={(event) => { handleSetInputs('length', event.target.value) }}
                        required
                    />
                </FormControl>
                <FormControl className={classes.formControlSmall}>
                    <TextField
                        label="Weight g"
                        variant="outlined"
                        type="number"
                        value={inputs.weight}
                        error={formErrors.weight ? true : false}
                        helperText={formErrors.weight ? formErrors.weight : ''}
                        onChange={(event) => { handleSetInputs('weight', event.target.value) }}
                        required
                    />
                </FormControl>
                <FormControl className={classes.formControlSmall}>
                    <TextField
                        variant="outlined"
                        value={processType}
                        label="Process"
                        select
                        required
                        error={formErrors.bagout && formErrors.packout && formErrors.supplies ? true : false}
                        helperText={formErrors.bagout && formErrors.packout && formErrors.supplies ? "Must select a Process Type" : ''}
                        onChange={(event: any) => {
                            handleSetProcessType(event.target.value)
                        }}
                    >
                        {dataList.ProcessTypes.map((element: any, index: number) => <MenuItem key={index} value={element.value}>{element.label}</MenuItem>)}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl className={classes.formControlLarge}>
                    <TextField
                        label="Description"
                        variant="outlined"
                        value={inputs.description}
                        error={formErrors.description ? true : false}
                        helperText={formErrors.description ? formErrors.description : ''}
                        onChange={(event) => { handleSetInputs('description', event.target.value) }}
                        required
                    />
                </FormControl>
            </Grid>
            {submitError && <p className={classes.errorMsg}>{submitError}</p>}
            {isLoading ?
            <Loading message="" />
            :
            <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                    <Button
                        onClick={handleSave}
                        type="button"
                        style={{ marginTop: 12 }}
                        className={classes.searchButton}
                        variant="contained"
                        color="primary"
                    >
                        SAVE
                    </Button>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <Button
                        onClick={handleCancel}
                        type="button"
                        style={{ marginTop: 12, marginLeft: 0 }}
                        className={classes.cancelBtn}
                        variant="contained"
                        color="default"
                    >
                        CANCEL
                    </Button>
                </FormControl>
            </Grid>}
        </Grid>
      </div>
    </div >
  )
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
    createPackingItem: (payload: any) => dispatch(ProductActions.createPackingItem(payload)),
    updatePackingItem: (payload: any) => dispatch(ProductActions.updatePackingItem(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackingItemFormModal);
