import { connect } from 'react-redux';
import { RxActions, UIActions } from '../../redux/actions';
import { AlertTransactionsComponent } from './alert-transactions.component';

const mapStateToProps = (state: any) => ({
  authUser: {
    email: "fake@example.com",
    firstName: "Joe",
    id: "qqqqqq33447475757",
    lastLogin: "2022-03-16T21:54:34.849Z",
    lastName: "Fakerson"
  },
  alertTransactions: state.rx.alertTransactions ? state.rx.alertTransactions.result : [],
  totalAlertTransactions: state.rx.alertTransaction ? state.rx.alertTransaction.total_records : 0,
  globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,

});

const mapDispatchToProps = (dispatch: any) => ({
  getAlertTransactions: (filter: any) => dispatch(RxActions.getAlertTransactions(filter)),
  resolveRx: (payload: any) => dispatch(RxActions.resolveRx(payload)),
  setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIActions.setGlobalRowsPerPage(rowsPerPage)),
});

export const AlertTransactionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertTransactionsComponent);