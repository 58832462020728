import createReducer from './createReducer';
import { ProductActions } from '../actions'
import { PRODUCTS } from '../constants/actions';


export const product = createReducer({}, {
  [PRODUCTS.LIST_RESPONSE](state = {}, action: ReturnType<typeof ProductActions.setProducts>) {
    let formattedData = action.payload.result || []
		formattedData.map((row: any) => {
			row.packSets = JSON.stringify(row.packSets || [])
			row.productDimensions = JSON.stringify(row.productDimensions || [])
      return row
		})
    return { ...state, products: { ...action.payload, result: formattedData } }
  },

  [PRODUCTS.SET_PRELOADED_PRODUCTS](state = {}, action: ReturnType<typeof ProductActions.setPreloadedProducts>) {
    let formattedData = action.payload.result || []
		formattedData.map((row: any) => {
			row.packSets = JSON.stringify(row.packSets || [])
			row.productDimensions = JSON.stringify(row.productDimensions || [])
      return row
		})
    return { ...state, preloadedProducts: { ...action.payload, result: formattedData } }
  },

  [PRODUCTS.SET_PACKING_ITEMS](state = {}, action: ReturnType<typeof ProductActions.setPackingItems>) {
    return { ...state, packingItems: action.payload }
  },

  [PRODUCTS.SET_PACK_SETS](state = {}, action: ReturnType<typeof ProductActions.setPackSets>) {
    return { ...state, packSets: action.payload }
  },
});