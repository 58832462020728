import Api from "../lib/api";
import { API_BASE_URL } from "../constants/api.url";

export class CustomerService {

  private static instance: CustomerService;
  private constructor() { }

  public static getInstance(): CustomerService {
    if (!CustomerService.instance) {
      CustomerService.instance = new CustomerService();
    }
    return CustomerService.instance;
  }

  public getCustomers = async (filter: any) : Promise<any> => {
    const params = filter ? [{key: 'filter', value: JSON.stringify(filter)}] : null;
    return await Api.get(API_BASE_URL.API_GATEWAY + '/customers', params)
  }

}
