import React from 'react';
import { Container, Typography, CircularProgress } from '@material-ui/core/';
import './loading.css';
import { globalColors } from '../../hooks/styles/muiTheme';

type LoadingProps = {
  message: string
}

const Loading: React.FC<LoadingProps> = ({ message }) => {
  return (
    <Container maxWidth="sm" className="page-wrap-container">
      <div className="page-wrap">
        <CircularProgress style={{color: globalColors.NF_BLUE}} />
        <Typography variant="h6" align="center">{message}</Typography>
      </div>
    </Container>
  )
}

export default Loading;