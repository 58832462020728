import React from 'react';
import { connect } from 'react-redux';
import { FormControl, Grid, MenuItem, Paper, TextField } from '@material-ui/core';
import { getModalClasses, CommonStyles, getModalStyle } from '../../hooks/styles';
import Loading from '../../components/loading';
import CancelIcon from '@material-ui/icons/Cancel';
import { GroupActions, RxActions } from '../../redux/actions';
import moment from 'moment';

const ShipmentGroupDetailsModal: React.FC<any> = (props) => {
    const classes = CommonStyles();
    const modalClasses = getModalClasses();
    const [modalStyle] = React.useState(getModalStyle());
    const [fetchDetailsError, setFetchDetailsError] = React.useState<string | null>(null)
    const [groupDetailsLoading, setGroupDetailsLoading] = React.useState(false)
    const [groupDetailsLoaded, setGroupDetailsLoaded] = React.useState(false)
    const [expectedDeliveryIndex, setExpectedDeliveryIndex] = React.useState<any>(null)
    const [selectedServiceType, setSelectedServiceType] = React.useState<string>('')

    const getGroupDetails = () => {
        setGroupDetailsLoading(true)
        props.getGroupDetails({
            id: props.shipmentGroup.id,
            success: () => {
                setGroupDetailsLoading(false)
                setGroupDetailsLoaded(true)
            },
            error: (errorMessage: string) => {
                setGroupDetailsLoading(false)
                setFetchDetailsError(errorMessage)
            }
        })
    }

    React.useEffect(() => {
        getGroupDetails()
    }, [])

    React.useEffect(() => {
        if (groupDetailsLoaded && !props.groupDetails) props.closeModal()
        else if (props.groupDetails) setSelectedServiceType(props.groupDetails.serviceType)
    }, [props.groupDetails])

    React.useEffect(() => {
        if (!props.groupDetails) return
        const serviceType = props.serviceTypes.find((serviceType: any) => serviceType.serviceType === props.groupDetails.serviceType)
        getExpectedDeliveryIndex(serviceType)
    }, [props.groupDetails])

    const getExpectedDeliveryIndex = (serviceType: any) => {
        props.groupDetails.forecastedMaxTemps.forEach((temp: any, index: number) => {
            const forecastDate = moment(props.groupDetails.forecastFetchDate, 'YYYY-MM-DD').add(index, 'days')
            const today = moment()
            if (forecastDate.isSame(today, 'date')) {
                if ((index + 1 + serviceType.deliveryDays) <= props.groupDetails.daysForecasted) {
                    setExpectedDeliveryIndex(index + serviceType.deliveryDays)
                }
            }
        })
    }

    const renderBagout = (bagout: any) => {
        return <>
            <b>Rx Id: </b>{bagout.rxId}, <b>Items: </b>{bagout.products?.map((product: any, index: number) => (<span key={index}>{product.productId} ({product.qty}), </span>))}<b>Bag:</b> {bagout.bag.replaceAll(' ', '')}
        </>
    }

    return (
        <div style={{ ...modalStyle, width: 1000 }} className={modalClasses.paper}>
            <div>
                <h3>Shipment Group Id# {props.shipmentGroup.id}</h3>
                {groupDetailsLoading ?
                    <Loading message="" />
                    :
                    <>
                        {!fetchDetailsError && props.groupDetails &&
                            <Grid container spacing={1}>
                                {props.groupDetails.daysForecasted && <Grid item xs={12}>
                                    <h4>{props.groupDetails.daysForecasted} Day Weather Forecast</h4>
                                    <div style={{ display: 'grid', gridTemplateColumns: props.groupDetails.forecastedMaxTemps.map(() => '1fr').join(' '), gridGap: '10px' }}>
                                        {props.groupDetails.forecastedMaxTemps.map((temp: any, index: number) => (
                                            <div key={index} style={{ position: 'relative', textAlign: 'center', backgroundColor: '#dfdfdf', borderRadius: '2px', padding: '5px' }} className={index === expectedDeliveryIndex ? classes.expectedDeliveryWrap : ''}>
                                                {moment(props.groupDetails.forecastFetchDate, 'YYYY-MM-DD').add(index, 'days').format('MMMM Do')}
                                                <br />
                                                High {props.groupDetails.forecastedMaxTemps[index]}&#176;F<br />
                                                Low {props.groupDetails.forecastedMinTemps[index]}&#176;F
                                                {expectedDeliveryIndex === index && <div className={classes.expectedDeliveryText}>Estimated Delivery</div>}
                                            </div>
                                        ))}
                                    </div>
                                </Grid>}
                                <Grid item xs={12}>
                                    <h4>Delivery Address</h4>
                                        <>
                                            {props.groupDetails.shipAddress} {props.groupDetails.shipAddress2}<br />
                                            {props.groupDetails.shipCity}, {props.groupDetails.shipState} {props.groupDetails.shipZip}
                                        </>
                                </Grid>
                                <Grid item xs>
                                    <h4>Service Type</h4>
                                    <FormControl className={classes.formControl} style={{ minWidth: '200px', marginTop: '-12px' }}>
                                        <TextField
                                            select
                                            variant="outlined"
                                            size="small"
                                            value={selectedServiceType}
                                            onChange={(e: any) => {
                                                setSelectedServiceType(e.target.value)
                                                const {carrier} = props.serviceTypes.find((serviceType: any) => serviceType.serviceType === e.target.value)
                                                props.updateGroupServiceType({ id: props.groupDetails.groupId, carrier: carrier, serviceType: e.target.value, success: () => {} })
                                            }}
                                            disabled={props.groupDetails?.groupState !== 'PENDING'}
                                            required
                                        >
                                        {props.serviceTypes.map((serviceType: any) => <MenuItem value={serviceType.serviceType}>{serviceType.serviceType}</MenuItem>)}
                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs>
                                    <h4>Ship Date</h4>
                                    {moment.utc(props.groupDetails.shipDate).format('YYYY-MM-DD')}
                                </Grid>
                                <Grid item xs>
                                    <h4>Status</h4>
                                    {props.groupDetails.groupState}
                                </Grid>
                                <Grid item xs={12}>
                                    <h4>Packages in Shipment</h4>
                                    <div style={{ maxHeight: 350, overflow: 'scroll', padding: '0 10px', backgroundColor: '#dfdfdf' }}>
                                        {(props.groupDetails.groupPackouts || []).map((packout: any, index: number) => (
                                            <Paper key={index} style={{ width: '100%', padding: 10, margin: '10px 0' }}><Grid container spacing={1}>
                                                <Grid item xs>
                                                    <h5 style={{ margin: '5px 0' }}>Package Weight</h5>
                                                    {packout.packWeight}g
                                                </Grid>
                                                {packout.shipment?.shipmentId &&
                                                    <Grid item xs>
                                                        <h5 style={{ margin: '5px 0' }}>Shipment ID</h5>
                                                        {packout.shipment.shipmentId}
                                                    </Grid>
                                                }
                                                {packout.shipment?.trackingNumber &&
                                                    <Grid item xs>
                                                        <h5 style={{ margin: '5px 0' }}>Tracking Number</h5>
                                                        {packout.shipment.trackingNumber}
                                                    </Grid>
                                                }
                                                <Grid item xs={12}>
                                                    <h5 style={{ marginBottom: 5 }}>Packing Items</h5>
                                                    {packout.packingItems.map((packingItem: any, index: number) => (
                                                        <span key={index}>{packingItem.packingItemId} ({packingItem.qty}){packingItem.uom}<br /></span>
                                                    ))}
                                                <Grid item xs={12}>
                                                </Grid>
                                                    <h5 style={{ marginBottom: 5, borderBottom: '1px solid #dfdfdf' }}>Prescription Bags</h5>
                                                    {(packout.bagouts || []).map((bagout: any, index: number) => (
                                                        <p key={index}>
                                                            {renderBagout(bagout)}
                                                        </p>
                                                    ))}
                                                </Grid>
                                            </Grid></Paper>
                                        ))}
                                    </div>
                                </Grid>
                            </Grid>
                        }
                        {fetchDetailsError && <p className={classes.errorMsg}>{fetchDetailsError}</p>}
                    </>
                }
                <div className={classes.closeBtn}>
                    <CancelIcon color="disabled" onClick={() => {
                        props.closeModal()
                        props.clearGroupDetails()
                    }}></CancelIcon>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state: any) => ({
    shipmentGroupRx: state.group.rxList ? state.group.rxList.result : [],
    totalShipmentGroupRx: state.group.rxList ? state.group.rxList.total_record : 0,
    serviceTypes: state.ui.serviceTypes,
    groupDetails: state.group.groupDetails
});

const mapDispatchToProps = (dispatch: any) => ({
    getGroupDetails: (payload: any) => dispatch(GroupActions.getGroupDetails(payload)),
    updateRx: (payload: any) => dispatch(RxActions.updateRx(payload)),
    clearGroupDetails: () => dispatch(GroupActions.setGroupDetails(null)),
    updateGroupServiceType: (payload: any) => dispatch(GroupActions.updateGroupServiceType(payload))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShipmentGroupDetailsModal);
