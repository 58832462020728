import { connect } from 'react-redux';
import { RxActions, UIActions } from '../../redux/actions';
import { PrescriptionsComponent } from './prescriptions.component';

const mapStateToProps = (state: any) => ({
  authUser: {
    email: "fake@example.com",
    firstName: "Joe",
    id: "qqqqqq33447475757",
    lastLogin: "2022-03-16T21:54:34.849Z",
    lastName: "Fakerson"
  },
  prescriptions: state.rx.rxList ? state.rx.rxList.result : [],
  totalPrescriptions: state.rx.rxList ? state.rx.rxList.total_records : 0,
  serviceTypes: state.ui.serviceTypes ? state.ui.serviceTypes : [],
});

const mapDispatchToProps = (dispatch: any) => ({
  getPrescriptions: (filter: any) => dispatch(RxActions.getRxList(filter)),
  cancelRx: (payload: any) => dispatch(RxActions.cancelRx(payload)),
  importReprocess: (payload: any) => dispatch(RxActions.importReprocess(payload))
});

export const PrescriptionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrescriptionsComponent);