export const Constants = {
    DATE_FORMAT: 'YYYY-MM-DD',
    ALERT: {
      NO_RECORD_FOUND: 'Sorry, no matching records found.',
      NOT_AUTHORIZED: 'You are not authorized to view this content.',
      SERVER_ERROR: 'A server error has occurred.',
      FAILED_TO_GET_CHANNEL: 'Failed to get channel types.',
      ERROR_ON_SAVING_CHANNEL: 'An error occurred while saving this channel group.',
      FAILED_TO_UPDATE_SAP: 'Failed to update SAP records.',
      DELAY: 6000
    },
    PAGE_TITLE: {
      PRODUCTS:'Products',
      PACK_SETS:'Pack Sets',
      PACKING_ITEMS:'Packing Items',
      BAGS:'Bags',
      PACKAGES:'Packages',
      USER_MANAGEMENT: 'User Management',
      RX_EXCEPTIONS: 'Rx Exceptions',
      SHIPMENT_GROUPS: 'Shipment Groups',
      PRESCRIPTIONS: 'Prescriptions',
      WORKGROUPS: 'Workgroups',
      INVENTORY_REPORT: 'Inventory Report',
    },
    //for api calls to get lists of available help videos. Doesn't need to be secure bc it allows access to public account info only
    VIMEO_APP_AUTH_KEY: 'basic NDg3MDBhZjQ0NWYxMjNlMTZhMjNhMTJjMTJhOGFmNGRlM2I4YzQ4Yzora25ScExISzZBRXhBSjh4NE5ma0xlYTlvbDRvRVVua1lZMTBFRW5WbW5rcVJqUWc4OXRCaU5MNWp6ZDkvQVZKemtRSjAxRytTdzlOQ3B4VEVsMUJqTE1BSG1URFNOR0Jha3lIQWJsb2FMUjBaNjd6U3N4V1UrY055aTFKSG1CeQ=='
};

export const List = {
    ScheduleReportFrequency: [
        'NEVER_SCHEDULE',
        'DAILY_RNI_SAVE_SEARCH_REPORT',
        'WEEKLY_RNI_SAVE_SEARCH_REPORT',
        'MONTHLY_RNI_SAVE_SEARCH_REPORT',
    ],
    ScheduleEnablePages: [
        'RNI:INVENTORY_HISTORY',
        'RNI:TRANSACTION_LOG',
        'RNI:DISPENSED_PRODUCTS',
        'RNI:WAREHOUSE_SHIPMENTS',
        'RNI:CABINET_TEMPERATURE'
    ]
}

