import * as React from 'react';
import { Button, FormControl, Grid, Modal, Tooltip } from '@material-ui/core';
import { CommonStyles } from '../../hooks/styles';
import dataList from '../../constants/dataList';
import MUIDataTable from "mui-datatables";
import Loading from '../../components/loading';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchFilter from '../../components/searchFilter';
import AssignWorkgroupModal from './assign-workgroup.modal';
import ShipmentGroupDetailsModal from './shipment-group-details.modal';
import moment from 'moment';
import { Alert } from '@mui/material';

interface ShipmentGroupsProps {
	authUser?: any
	shipmentGroups: any[]
	totalShipmentGroups: any
	getShipmentGroups: (filter: any) => void
	setGlobalRowsPerPage: (numRows: any) => void
}

export const ShipmentGroupsComponent: React.FC<ShipmentGroupsProps> = (props) => {
	const classes = CommonStyles();
	const firstRender = React.useRef(false);
	const [modalOpen, setModalOpen] = React.useState(false)
	const [detailsModalOpen, setDetailsModalOpen] = React.useState(false)
	const [groupDetails, setGroupDetails] = React.useState<any>(null)
	const [selectedGroups, setSelectedGroups] = React.useState<any[]>([])
	const [selectedRows, setSelectedRows] = React.useState<any[]>([])
	const [sortOrder, setSortOrder] = React.useState(null as any)
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
	const [filters, setFilters] = React.useState<any>({groupState: 'PENDING'})
	const [selectedProductId, setSelectedProductId] = React.useState<any>(null)
	const RESULT_LIMIT = 500

	const renderIcons: any = (tableMeta: any) => {
		return (
			<div style={{ color: 'rgba(0, 0, 0, 0.54)', float: 'right' }}>
				<Tooltip title="Details" placement="bottom-start">
					<VisibilityIcon
						onClick={(e) => {
							setDetailsModalOpen(true)
							setGroupDetails(props.shipmentGroups[tableMeta.rowIndex])
						}}
						style={{ marginRight: 10, cursor: 'pointer' }}
					/>
				</Tooltip>
			</div>
		)
	}

	const columnData = [
		{
			label: "Shipment Group Id",
			name: "id",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Product Id",
			name: "productId",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Prescription Count",
			name: "rxCount",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Service Type",
			name: "serviceType",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Ship Date",
			name: "shipDate",
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any, tableMeta: any) => { 
				return moment.utc(value).format('YYYY-MM-DD')
			}
		},
		{
			label: "Status",
			name: "groupState",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Workgroup",
			name: "workgroups",
			change: true,
			viewColumns: true,
			display: true,
			sort: false,
			customBodyRender: ((value: any) => {
				const workgroup: any = JSON.parse(value)
				return workgroup ? workgroup[0].name : ''
			})
		},
		{
			label: "",
			name: "",
			change: false,
			viewColumns: false,
			display: true,
			sort: false,
			customBodyRender: (value: any, tableMeta: any) => { return renderIcons(tableMeta) }
		},
	]

	const getTableColumns = () => {
		return columnData.map((column: any, index: number) => {
			return {
				label: column.label,
				name: column.name,
				options: {
					filter: true,
					display: tableStatePersist ? tableStatePersist.columns[index].display : column.display,
					change: column.change,
					sort: column.sort ?? true,
					viewColumns: column.viewColumns,
					...(column.customBodyRender) && { customBodyRender: column.customBodyRender },
					...(column.customBodyRenderLite) && { customBodyRenderLite: column.customBodyRenderLite },
				}
			}
		})
	}

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			filterShipmentGroups()
		}
		else {
			firstRender.current = false;
		}
	}, [sortOrder])

	const getFilter = (reset?: boolean) => {
		const filter: any = {
			limit: RESULT_LIMIT,
			order: sortOrder ? [sortOrder] : ['id'],
			where: {} //filters ultimately end up here 
		}

		Object.keys(filters).forEach((filterKey: string) => {
			if (filterKey === 'rxCount') return
			if (['id', 'productId'].includes(filterKey)) filter.where[filterKey] = filters[filterKey] ? {ilike: `%${filters[filterKey]}%`} : undefined
			else filter.where[filterKey] = filters[filterKey] || undefined
		})

		let payload: any = {}

		if (filters['rxCount']) {
			payload.rxCount = filters['rxCount']
		}

		if (sortOrder && sortOrder.includes('rxCount')) {
			payload.sortRxCount = sortOrder.split(' ')[1]
			delete filter.order
		}

		payload.filter = filter

		return payload
	}

	const filterShipmentGroups = (reset?: boolean) => {
		clearSelection()
		props.getShipmentGroups(getFilter(reset))
	}

	const showLoadingMsg = () => (
		!props.authUser && props.shipmentGroups.length === 0
	)

	const handleModalClose = () => {
		setModalOpen(false);
		setDetailsModalOpen(false)
	};

	const triggerModal = () => {
		setModalOpen(true);
	}

	const clearSelection = () => {
		setSelectedProductId(null)
		setSelectedGroups([])
		setSelectedRows([])
	}

	const letsFindOut = (dataIndex: number) => {
		return (!selectedProductId || props.shipmentGroups[dataIndex].productId === selectedProductId) && props.shipmentGroups[dataIndex].groupState === "PENDING"
	}

	const showSelectableRowsHeader = () => {
		return props.shipmentGroups.every((group: any) => props.shipmentGroups[0].productId === group.productId && group.groupState === "PENDING" && props.shipmentGroups[0].shipDate === group.shipDate)
	}

	return (<div>
		{showLoadingMsg() ? <div><Loading message="" /></div> : <div>
			<div className={classes.searchWrap}>
				<Grid container>
					<Grid item xs={'auto'}>
						<SearchFilter
							config={[
								{
									label: 'Shipment Group Id',
									name: 'id',
									type: 'text'
								},
								{
									label: 'Ship Date',
									name: 'shipDate',
									type: 'date'
								},
								{
									label: 'Product Id',
									name: 'productId',
									type: 'text'
								},
								{
									label: 'Status',
									name: 'groupState',
									type: 'autoComplete',
									selectOptions: dataList.GroupStatus.map((status: any) => ({ name: status.label, id: status.value }))
								},
								{
									label: 'Rx Count',
									name: 'rxCount',
									type: 'number',
									nonNegative: true
								}
							]}
							onFilterChange={setFilters}
							filters={filters}
							handleSearch={filterShipmentGroups}
						/>
					</Grid>
				</Grid>
			</div>
			<div className={classes.pageActionsWrap}>
				{selectedGroups.length > 0 && <FormControl className={classes.formControl}>
					<Button
						className={classes.viewAllBtn}
						variant="contained"
						color="secondary"
						onClick={() => {
							triggerModal()
						}}
					>
						Create Workgroup
					</Button>
				</FormControl>}
			</div>
			<div>
				<MUIDataTable
					data={props.shipmentGroups}
					columns={getTableColumns()}
					options={{
						filterType: "dropdown",
						responsive: "simple",
						filter: false,
						search: false,
						download: true,
						print: true,
						selectableRows: 'multiple',
						serverSide: true,
						selectToolbarPlacement: 'none',
						pagination: false,
						rowsSelected: selectedRows,
						selectableRowsHeader: showSelectableRowsHeader(),
						onDownload: (buildHead: any, buildBody: any, columns: any) => {
							const filter = getFilter()
							delete filter.limit
							delete filter.offset
							const exportOptions = {
								columns: columns,
								timestampFormat: 'YYYY-MM-DD',
								fileName: 'shipment-groups'
							}
							// add filter for rxCount
							props.getShipmentGroups({ filter: filter, exportOptions: exportOptions })
							return false
						},
						isRowSelectable: letsFindOut,
						onRowSelectionChange: (currentRowsSelected: any, allRowsSelected: any, rowsSelected: any) => {
							setSelectedRows(rowsSelected)
							setSelectedGroups(allRowsSelected.map((row: any) => props.shipmentGroups[row.dataIndex]))
						},
						onColumnSortChange: (changedColumn: string, direction: string) => {
							setSortOrder(`${changedColumn} ${direction.toUpperCase()}`)
						},
						onTableChange: (tableAction: any, tableState: any) => {
							switch (tableAction) {
								case 'viewColumnsChange':
									setTableStatePersist(tableState);
									break;
								case 'rowSelectionChange':
									if(tableState.selectedRows.data.length === 0){
										setSelectedProductId(null);
									}
									else{
										const firstRowData = props.shipmentGroups[tableState.selectedRows.data[0].index]
										setSelectedProductId(firstRowData.productId)
									}
									break;
							}
						},
					}}
				/>
				{props.shipmentGroups && props.shipmentGroups.length === RESULT_LIMIT && <Alert severity="error">Results limited to {RESULT_LIMIT}. Apply more filters to reduce results.</Alert>}
			</div>
			<Modal
				open={modalOpen || detailsModalOpen}
				onClose={(e: any, reason: string) => {
					if (reason === 'backdropClick') return
					handleModalClose()
				}}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<>
					{modalOpen && <div>
						<AssignWorkgroupModal groupIds={selectedGroups.map((group: any) => ({id: group.id}))} assignWorkgroup={() => { }} getShipmentGroups={() => props.getShipmentGroups(getFilter(false))} closeModal={handleModalClose} onSuccess={clearSelection} />
					</div>}
					{detailsModalOpen && <div>
						<ShipmentGroupDetailsModal shipmentGroup={groupDetails} getShipmentGroups={() => props.getShipmentGroups(getFilter(false))} closeModal={handleModalClose} />
					</div>}
				</>
			</Modal>
		</div>}
	</div>)
}