import React from 'react';
import { connect } from 'react-redux';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { getModalClasses, CommonStyles, getModalStyle } from '../../hooks/styles';
import Loading from '../../components/loading';
import MUIDataTable from "mui-datatables";
import dataList from '../../constants/dataList';
import CancelIcon from '@material-ui/icons/Cancel';
import { WorkgroupActions } from '../../redux/actions';

const WorkgroupDetailsModal: React.FC<any> = (props) => {
  const classes = CommonStyles();
  const modalClasses = getModalClasses();
  const [modalStyle] = React.useState(getModalStyle());
  const [shipmentGroups] = React.useState<any[]>(props.workgroup.groups || [])
  const [isLoading] = React.useState<boolean>(false)
  const [submitError] = React.useState<string | null>(null)
  const [currentTab, setCurrentTab] = React.useState('shipmentGroups')

  const onTabChange = (event: any, newTab: string) => {
    if (currentTab === newTab) return
    setCurrentTab(newTab)
  }

  const columnData = [
    {
      label: "Shipment Group Id",
      name: "id",
      change: true,
      viewColumns: true,
      display: true
    },
    {
      label: "Service Type",
      name: "serviceType",
      change: true,
      viewColumns: true,
      display: true
    },
    {
      label: "Group State",
      name: "groupState",
      change: true,
      viewColumns: true,
      display: true
    }
  ]

  const getTableColumns = () => {
    return columnData.map((column: any, index: number) => {
      return {
        label: column.label,
        name: column.name,
        options: {
          filter: true,
          change: column.change,
          viewColumns: column.viewColumns,
          ...(column.customBodyRender) && { customBodyRender: column.customBodyRender },
        }
      }
    })
  }

  return (
    <div style={{ ...modalStyle, width: 1000 }} className={modalClasses.paper}>
      <div>
        <h2 id="simple-modal-title">Workgroup Details</h2>
        <Grid container>
          <Grid item xs={4}>
            <h4>Workgroup Id</h4>
            {props.workgroup.id}
          </Grid>
          <Grid item xs>
            <h4>Workgroup Name</h4>
            {props.workgroup.name}
          </Grid>
          <Grid item xs>
            <h4>Status</h4>
            {props.workgroup.status}
          </Grid>
        </Grid>
        <div style={{ marginBottom: 20 }} />
        <Tabs value={currentTab} onChange={onTabChange} aria-label="basic tabs example">
          <Tab label="Shipment Groups" value="shipmentGroups" />
        </Tabs>
        <Grid container>
          {currentTab === 'shipmentGroups' && <Grid item xs={12}>
            <>
              {shipmentGroups.length > 0 &&
                <MUIDataTable
                  title={''}
                  data={shipmentGroups}
                  columns={getTableColumns()}
                  options={{
                    responsive: 'standard',
                    fixedHeader: true,
                    tableBodyMaxHeight: "450px",
                    rowsPerPage: props.globalRowsPerPage,
                    rowsPerPageOptions: dataList.PageLimitOptions,
                    filterType: "dropdown",
                    filter: false,
                    search: false,
                    download: false,
                    print: false,
                    viewColumns: false,
                    selectableRows: 'none',
                    serverSide: true,
                  }}
                />
              }
            </>
          </Grid>}
          <Grid item xs={12}>
            {isLoading ? <Loading message="" /> : <p className={classes.errorMsg}>{submitError}</p>}
          </Grid>
        </Grid>
        <div className={classes.closeBtn}>
          <CancelIcon color="disabled" onClick={() => props.closeModal()}></CancelIcon>
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  updateWorkgroup: (payload: any) => dispatch(WorkgroupActions.update(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkgroupDetailsModal);
