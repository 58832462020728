import { makeStyles } from '@material-ui/core/styles';
import { globalColors } from '../../hooks/styles/muiTheme';

const drawerWidth = 240;

export const BasePageStyles = makeStyles((theme) => (

  {
    //top-level customizations for the entire app can be made here
    //this is useful for 3rd party non-MUI components we lack internal control over,
    // eg Highcharts
    root: {
      display: 'flex',
      color: theme.palette.text.secondary,

      '& .highcharts-background': {
        fill: theme.palette.background.paper
      },

      '& .highcharts-title':  {
        fill: `${theme.palette.text.secondary} !important`
      },

      '& .highcharts-axis-labels text': {
        fill: `${theme.palette.text.secondary} !important`
      },

      '& .highcharts-axis-title': {
        fill: `${theme.palette.text.secondary} !important`
      },

      '& .highcharts-legend-item text': {
        fill: `${theme.palette.text.secondary} !important`
      }      
    },
    pageTitle: {
      color: theme.palette.text.secondary
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarMobile: {
      paddingRight: 0
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    profileMenuBtn: {
      color: 'white',
      backgroundColor: 'inherit !important'
    },
    avatar: {
      margin: '0 auto',
      fontSize: 16
    },
    profileName: {
      padding: '6px 0 6px 10px'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.secondary.contrastText,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonMobile: {
      marginRight: 10,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
      color: theme.palette.text.secondary
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100%',
      minHeight: '100vh',
      paddingBottom: 50,
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    topmenu:{
      width:'100%',
      display:'inherit'
    },
    link: {
      'text-decoration': 'inherit',
      color: 'inherit',
      '&:focus, &:hover, &:visited, &:link, &:active': {
        'text-decoration': 'inherit',
        color: 'inherit'
      }
    },
    errorAlert: {
      /*position: 'absolute',
      bottom: '20px',
      right: '20px',*/
      paddingLeft:'24px',
      paddingRight:'24px'
    },
    leftColumn: {
      backgroundColor: (theme.palette.type === 'dark') ? globalColors.MEDIUM_GRAY : 'inherit'
    }
  }

  ), { index: 1 });
  export default BasePageStyles;
