import { connect } from 'react-redux';
import { ProductActions, UIActions } from '../../redux/actions';
import { PackingItemsComponent } from './packing-items.component';

const mapStateToProps = (state: any) => ({
    authUser: {
      email: "fake@example.com",
      firstName: "Joe",
      id: "qqqqqq33447475757",
      lastLogin: "2022-03-16T21:54:34.849Z",
      lastName: "Fakerson"
    },
    packingItems: state.product.packingItems ? state.product.packingItems.result : [],
    totalPackingItems: state.product.packingItems ? state.product.packingItems.total_records : 0,
    globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
  });
  
  const mapDispatchToProps = (dispatch: any) => ({
    getPackingItems: (filter: any) => dispatch(ProductActions.getPackingItems(filter)),
    deletePackingItem: (payload: any) => dispatch(ProductActions.deletePackingItem(payload)),
    setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIActions.setGlobalRowsPerPage(rowsPerPage)),
  });

export const PackingItemsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackingItemsComponent);