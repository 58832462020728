import { parse } from 'json2csv';
import moment from 'moment';

export const applyExportOptions = (options: any, data: any) => {
  const cols = options.columns.filter((col: any) => {
    let validCol = false
    if (col.display === "true") {
      validCol = true
    }
    if (!col.name) {
      validCol = false
    }
    return validCol
  })
  let headerMap: any = {}
  cols.forEach((col: any) => {
    headerMap[col.name] = col.label
  })
  const dataWithOptions = data.map((row: any) => {
    let rowWithOptions: any = {}
    Object.keys(row).forEach((rowKey: any) => {
      if (headerMap[rowKey]) {
        rowWithOptions[headerMap[rowKey]] = formatIfDateTime(options.timestampFormat, row[rowKey])
      }
    })
    return rowWithOptions
  })

  return dataWithOptions
}

export const downloadCsvFile = (fileName: string, data: any[]) => {
  const csv = parse(data)
  let blob = new Blob([csv]);
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}.csv`;
  link.click();
}

const formatIfDateTime = (format: string, value: any) => {
  const utc = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true)
  if (utc.isValid()) {
    return moment.utc(value).format(format)
  }
  return value
}