import createReducer from './createReducer';
import { UserManagementActions } from '../actions'
import { USER_MANAGEMENT } from '../constants/actions';


export const userManagement = createReducer({}, {
  [USER_MANAGEMENT.LIST_RESPONSE](state = {}, action: ReturnType<typeof UserManagementActions.setUsers>) {
    return { ...state, users: action.payload }
  },
  [USER_MANAGEMENT.SET_AUTH_USER](state = {}, action: ReturnType<typeof UserManagementActions.setAuthUser>) {
    return { ...state, authUser: action.payload }
  }
});