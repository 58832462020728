import * as React from "react";

import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';

import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { themeNames } from "../hooks/styles/muiTheme";
import { getMuiTheme } from "../hooks/styles";
import Products from "../pages/products";
import UserManagement from "../pages/user-management";
import AlertTransactions from '../pages/alert-transactions';
import ShipmentGroups from '../pages/shipment-groups';
import LoginPage from "../pages/login";
import { UserManagementActions } from "../redux/actions";
import Prescriptions from "../pages/prescriptions";
import Workgroups from "../pages/workgroups";
import PackingItems from "../pages/packing-items";
import PackSets from "../pages/pack-sets";
import InventoryReport from "../pages/inventory-report";

const forwardRoot = () => {
  const url = sessionStorage.getItem("url");
  return (<Redirect to={!url || url === '/' ? '/products' : `${url}`} />)
}

const Routes = (props: any) => {

  const authenticated = () => {
    if (props.authUser || sessionStorage.getItem('authUser')) {
      const sessionAuthUser = sessionStorage.getItem('authUser')
      const authUser = JSON.parse(sessionAuthUser || '{}')
      props.setAuthUser(authUser)
      return true
    }
    return false
  }

  const getSecureRoute = (path: string, component: any) => {
    return authenticated() ? <Route exact path={path} component={component} /> : <Redirect to={'/login'} />
  }

  return (
    <MuiThemeProvider theme={getMuiTheme(props.selectedTheme || themeNames.LIGHT)}>
      <CssBaseline>
        <Router>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            {getSecureRoute("/", forwardRoot)}
            {getSecureRoute("/products", Products)}
            {getSecureRoute("/packing-items", PackingItems)}
            {getSecureRoute("/pack-sets", PackSets)}
            {getSecureRoute("/rx-exceptions", AlertTransactions)}
            {getSecureRoute("/shipment-groups", ShipmentGroups)}
            {getSecureRoute("/workgroups", Workgroups)}
            {getSecureRoute("/user-management", UserManagement)}
            {getSecureRoute("/prescriptions", Prescriptions)}
            {getSecureRoute("/inventory-report", InventoryReport)}
          </Switch>
        </Router>
      </CssBaseline>
    </MuiThemeProvider>
  )
}

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser
})
const mapDispatchToProps = (dispatch: any) => ({
  setAuthUser: (authUser: any) => UserManagementActions.setAuthUser(authUser)
})

export default connect(mapStateToProps, mapDispatchToProps)(Routes);

