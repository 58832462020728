import React from 'react';
import { connect } from 'react-redux';
import { WorkgroupActions } from '../../redux/actions';
import { Button, Grid, FormControl, TextField, MenuItem } from '@material-ui/core';
import { getModalClasses, CommonStyles, getSmallModalStyle } from '../../hooks/styles';
import Loading from '../../components/loading';
import dataList from '../../constants/dataList';

const AssignWorkgroupModal: React.FC<any> = (props) => {
  const classes = CommonStyles();
  const modalClasses = getModalClasses();
  const [modalStyle] = React.useState(getSmallModalStyle());

  const getInitInputs = () => ({
    workGroupName: '',
    lane: 3
  })

  const [inputs, setInputs] = React.useState<any>(getInitInputs())
  const [formErrors, setFormErrors] = React.useState<any>({})

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [submitError, setSubmitError] = React.useState<string>('')

  React.useEffect(() => {
    if (!props.workgroups) {
      props.getWorkgroups()
    }
  }, [])

  const handleCancel = () => {
    props.closeModal();
  }

  const handleSetInputs = (key: string, value: any) => {
    setInputs({ ...inputs, [key]: value })
    setFormErrors({ ...formErrors, [key]: null })
    setSubmitError('')
  }

  const handleSave = () => {
    const errors: any = {}
    if (!inputs['workgroupName']) {
      errors['workgroupName'] = `A name for the new workgroup is required`
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }

    setIsLoading(true)

    const onSuccess = () => {
      setIsLoading(false)
      props.getShipmentGroups()
      props.onSuccess()
      props.closeModal()
    }

    const onError = () => {
      console.log('WORKGROUPS', props.workgroups)
      setIsLoading(false)
      setSubmitError('Failed to create new workgroup')
    }

    const payload = {
      requestBody: {},
      success: onSuccess,
      error: onError
    }

    submitNewWorkgroup(payload)
  }

  const submitNewWorkgroup = (payload: any) => {
    let requestBody: any = {
      workgroup: {
        name: inputs['workgroupName'],
        lane: inputs.lane,
        status: 'PENDING',
      },
      groups: props.groupIds
    }
    payload.requestBody = requestBody
    props.createWorkgroup(payload)
  }

  return (
    <div style={modalStyle} className={modalClasses.paper}>
      <div>
        <h3 id="simple-modal-title">Assign to Workgroup</h3>
        <Grid container>
          <Grid item xs={12}>
            <FormControl className={classes.formControlMedium}>
              <TextField
                label="Workgroup Name"
                variant="outlined"
                value={inputs.workgroupName || ''}
                error={formErrors.workgroupName ? true : false}
                helperText={formErrors.workgroupName ? formErrors.workgroupName : ''}
                onChange={(event) => { handleSetInputs('workgroupName', event.target.value) }}
                required
              />
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField
                  label="Lane"
                  variant="outlined"
                  value={inputs.lane || 3}
                  error={formErrors.lane ? true : false}
                  helperText={formErrors.lane ? formErrors.lane : ''}
                  onChange={(event) => { handleSetInputs('lane', event.target.value) }}
                  select
                  required
                >
                  {dataList.Lanes.map((element: any, index: number) => <MenuItem key={index} value={element.value}>{element.label}</MenuItem>)}
                </TextField>
              </FormControl>
          </Grid>
          <Grid item xs={12}>
            {isLoading ?
              <Loading message="" />
              :
              <>
                <FormControl className={classes.formControl}>
                  <Button
                    onClick={handleSave}
                    type="button"
                    style={{ marginTop: 12 }}
                    className={classes.searchButton}
                    variant="contained"
                    color="primary"
                  >
                    ASSIGN
                  </Button>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Button
                    onClick={handleCancel}
                    type="button"
                    style={{ marginTop: 12, marginLeft: 0 }}
                    className={classes.cancelBtn}
                    variant="contained"
                    color="default"
                  >
                    CANCEL
                  </Button>
                </FormControl>
              </>
            }
          </Grid>
        </Grid>
        {submitError &&
          <div style={{ display: 'block' }}>
            <p className={classes.errorMsg}>{submitError}</p>
          </div>
        }
      </div>
    </div >
  )
}

const mapStateToProps = (state: any) => ({
  workgroups: state.workgroup.workgroupList ? state.workgroup.workgroupList.result : null
});

const mapDispatchToProps = (dispatch: any) => ({
  getWorkgroups: () => dispatch(WorkgroupActions.getList({filter: {}})),
  createWorkgroup: (payload: any) => dispatch(WorkgroupActions.create(payload)),
  updateWorkgroup: (payload: any) => dispatch(WorkgroupActions.update(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignWorkgroupModal);
