import Api from "../lib/api";
import { RX } from "../constants/api.url";

export class RxService {

  private static instance: RxService;
  private constructor() { }

  public static getInstance(): RxService {
    if (!RxService.instance) {
      RxService.instance = new RxService();
    }
    return RxService.instance;
  }

  public getAlertTransactions = async (filter: any) : Promise<any> => {
    const params = [{key: 'filter', value: JSON.stringify(filter)}];
    return await Api.get(RX.RX_URL, params)
  }

  public getRxExceptionsCount = async () : Promise<any> => {
    const where = { stateId: { lt: 100 } }
    const params = [{key: 'where', value: JSON.stringify(where)}];
    return await Api.get(RX.RX_COUNT_URL, params)
  }

  public getRxList = async (filter: any) : Promise<any> => {
    const params = [{key: 'filter', value: JSON.stringify(filter)}];
    return await Api.get(RX.RX_URL, params)
  }

  public updateRx = async (payload: any) : Promise<any> => {
    return await Api.patch(`${RX.RX_URL}/${payload.id}`, payload)
  }

  public resolveRx = async (payload: any) : Promise<any> => {
    return await Api.patch(`${RX.RX_RESOLVE_URL}/${payload.requestBody.id}`, payload.requestBody)
  }

  public cancelRx = async (payload: any) : Promise<any> => {
    return await Api.delete(`${RX.RX_URL}/${payload.deleteId}`, payload.deleteId)
  }

  public importReprocess = async () : Promise<any> => {
    return await Api.get(RX.IMPORT_REPROCESS)
  }
}
