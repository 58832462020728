import Api from "../lib/api";
import { REPORTS } from '../constants/api.url';

export class ReportService {

  private static instance: ReportService;
  private constructor() { }

  public static getInstance(): ReportService {
    if (!ReportService.instance) {
        ReportService.instance = new ReportService();
    }
    return ReportService.instance;
  }

  public getInventoryReport = async (date: any): Promise<any> => {
    return await Api.get(`${REPORTS.INVENTORY_URL}/${date.startDate}/${date.endDate}`)
  }
}
