

import FDI_LOGO  from '../assets/images/FDI-logo.gif';
import FFF_LOGO  from '../assets/images/fff_logo.png';
import RNI_LOGO from '../assets/images/rni-logo.png';
import RNI_CLOUD_LOGO from '../assets/images/rni_cloud_logo.png';
import RNI_LOGO_SM from '../assets/images/rni-logo-sm.png';
import NUFACTOR_LOGO from '../assets/images/nufactor-logo.png';

export const IMAGES_URL = {
    FDI_LOGO: FDI_LOGO,
    FFF_LOGO: FFF_LOGO,
    RNI_LOGO: RNI_LOGO,
    RNI_LOGO_SM: RNI_LOGO_SM,
    RNI_CLOUD_LOGO: RNI_CLOUD_LOGO,
    NUFACTOR_LOGO: NUFACTOR_LOGO
};