import { call, put } from "redux-saga/effects";
import { Constants } from "../../constants/Constants";
import { ReportActions, UIActions } from "../actions";
import { ReportService } from "../services/report.service";
import { applyExportOptions, downloadCsvFile } from '../lib/dataExportUtils';

export function* getInventoryReport(action: ReturnType<typeof ReportActions.getInventoryReport>) {
    try {
        yield put(UIActions.showLoader(true));
        const { data } = yield call(ReportService.getInstance().getInventoryReport, action.payload.date);

        if (action.payload.exportOptions) {
            const dataWithOptions = applyExportOptions(action.payload.exportOptions, data.result)
            downloadCsvFile(action.payload.exportOptions.fileName, dataWithOptions)
            yield put(UIActions.showLoader(false));
            return;
        }
        yield put(ReportActions.setInventoryReport(data));
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (!action.payload.exportOptions) {
            yield put(ReportActions.setInventoryReport(null));
        }
        if (err instanceof Error) {
            const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            console.log(errorMessage, err);
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}
