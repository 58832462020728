import { call, put } from 'redux-saga/effects';
import { ProductActions, UIActions } from '../actions/index';
import { CustomerService } from '../services/customer.service';
import { ProductService } from '../services/product.service';

export function* updateUINavigateState(action: ReturnType<typeof UIActions.setNavigationState>) {
    try {
        yield put(UIActions.navigationStateSuccess(action.payload));
    } catch (err) {
        console.log("saga error", err);
    }
}

export function* preLoadData(action: ReturnType<typeof UIActions.preLoadData>): any {
    try {
        //Preload list of customers for autocomplete
        const customerResponse = yield call(CustomerService.getInstance().getCustomers, {})
        yield put(UIActions.setPreloadedCustomers(customerResponse.data))
        const poller = action.payload.poller()
        yield put(UIActions.setBackendPoller(poller))

        //Preload list of products for autocomplete
        const productsResponse = yield call(ProductService.getInstance().getProducts, {where: {active: true}, include: ['packSets', 'productDimensions']})
        yield put(ProductActions.setPreloadedProducts(productsResponse.data))

        //Preload list of service types for autocomplete
        const serviceTypesResponse = yield call(ProductService.getInstance().getServiceTypes, {})
        yield put(UIActions.setPreloadedServiceTypes(serviceTypesResponse.data))

        //Preload list of service types for autocomplete
        const addressTypesResponse = yield call(ProductService.getInstance().getAddressTypes, {})
        yield put(UIActions.setPreloadedAddressTypes(addressTypesResponse.data))
    } catch (err) {
        console.log('PRELOAD DATA SAGA ERROR:', err)
    }
}
