import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, FormControl, TextField, MenuItem } from '@material-ui/core';
import { getModalClasses, CommonStyles, getSmallModalStyle } from '../../hooks/styles';
import Loading from '../../components/loading';

interface UserFormProps {
  user: any
  getUsers: () => void
  inviteUser: (payload: any) => void
  updateUser: (payload: any) => void
  closeModal: () => void
}

const UserFormModal: React.FC<UserFormProps> = (props) => {
  const classes = CommonStyles();
  const modalClasses = getModalClasses();
  const [modalStyle] = React.useState(getSmallModalStyle());

  const getInitInputs = () => ({
    firstName: props.user?.firstName || '',
    lastName: props.user?.lastName || '',
    email: props.user?.email || '' as string,
    role: props.user?.role || ''
  })

  const [inputs, setInputs] = React.useState<any>(getInitInputs())
  const [formErrors, setFormErrors] = React.useState<any>({})

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [submitError, setSubmitError] = React.useState<string>('')

  const handleCancel = () => {
    props.closeModal();
  }

  const handleSetInputs = (key: string, value: any) => {
    setInputs({ ...inputs, [key]: value })
    setFormErrors({ ...formErrors, [key]: null })
    setSubmitError('')
  }

  const formatString = (str: string) => {
    let formatted = str[0].toUpperCase() + str.slice(1);
    return formatted.replace(/([A-Z])/g, ' $1').trim()
  }

  const handleSave = () => {
    const errors: any = {}
    for (let [key, value] of Object.entries(inputs)) {
      if (value === '') {
        errors[key] = `${formatString(key)} is required`
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }

    setIsLoading(true)

    const onSuccess = () => {
      setIsLoading(false)
      props.getUsers()
      props.closeModal()
    }

    const onError = () => {
      setIsLoading(false)
      setSubmitError('Failed to save user')
    }

    const requestBody = props.user ? getEditRequestBody() : getAddRequestBody()

    delete requestBody.createdAt

    const payload = {
      requestBody: requestBody,
      success: onSuccess,
      error: onError
    }

    const submitFn = props.user ? props.updateUser : props.inviteUser
    
    submitFn(payload)
  }

  const getAddRequestBody = () => ({
    firstName: inputs.firstName,
    lastName: inputs.lastName,
    email: inputs.email,
    role: inputs.role
  })

  const getEditRequestBody = () => ({
    ...props.user,
    ...{
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      email: inputs.email,
      role: inputs.role
    }
  })

  return (
    <div style={modalStyle} className={modalClasses.paper}>
      <div>
        <h3 id="simple-modal-title">{props.user ? 'Edit User' : 'Add User'}</h3>
        <Grid container>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                label="Email"
                variant="outlined"
                value={inputs.email}
                error={formErrors.email ? true : false}
                helperText={formErrors.email ? formErrors.email : ''}
                onChange={(event) => { handleSetInputs('email', event.target.value) }}
                required
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                id="user-role-selector"
                value={inputs.role}
                label="Role"
                select
                required
                error={formErrors.role ? true : false}
                helperText={formErrors.role ? formErrors.role : ''}
                onChange={(event: any) => {
                  handleSetInputs('role', event.target.value)
                }}
              >
                <MenuItem value={'administrator'}>administrator</MenuItem>
                <MenuItem value={'supervisor'}>supervisor</MenuItem>
                <MenuItem value={'user'}>user</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                label="First Name"
                variant="outlined"
                value={inputs.firstName}
                error={formErrors.firstName ? true : false}
                helperText={formErrors.firstName ? formErrors.firstName : ''}
                onChange={(event) => { handleSetInputs('firstName', event.target.value) }}
                required
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                label="Last Name"
                variant="outlined"
                value={inputs.lastName}
                error={formErrors.lastName ? true : false}
                helperText={formErrors.lastName ? formErrors.lastName : ''}
                onChange={(event) => { handleSetInputs('lastName', event.target.value) }}
                required
              />
            </FormControl>
          </Grid>
          {submitError && <p className={classes.errorMsg}>{submitError}</p>}
          {isLoading ?
            <Loading message="" />
            :
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <Button
                  onClick={handleSave}
                  type="button"
                  style={{ marginTop: 12 }}
                  className={classes.searchButton}
                  variant="contained"
                  color="primary"
                >
                  {props.user ? 'SAVE' : 'SEND'}
                </Button>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Button
                  onClick={handleCancel}
                  type="button"
                  style={{ marginTop: 12, marginLeft: 0 }}
                  className={classes.cancelBtn}
                  variant="contained"
                  color="default"
                >
                  CANCEL
                </Button>
              </FormControl>
            </Grid>}
        </Grid>
      </div>
    </div >
  )
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFormModal);
