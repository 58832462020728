import { connect } from 'react-redux';
import { UserManagementActions, UIActions } from '../../redux/actions';
import { UserManagementComponent } from './user-management.component';

const mapStateToProps = (state: any) => ({
  authUser: {
    email: "fake@example.com",
    firstName: "Joe",
    id: "qqqqqq33447475757",
    lastLogin: "2022-03-16T21:54:34.849Z",
    lastName: "Fakerson"
  },
  users: state.userManagement.users ? state.userManagement.users : [],
  totalUsers: state.userManagement.users ? state.userManagement.users.total_records : 0,
  globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,

});

const mapDispatchToProps = (dispatch: any) => ({
  getUsers: (filter: any) => dispatch(UserManagementActions.getUsers(filter)),
  inviteUser: (payload: any) => dispatch(UserManagementActions.inviteUser(payload)),
  updateUser: (payload: any) => dispatch(UserManagementActions.updateUser(payload)),
  deleteUser: (payload: any) => dispatch(UserManagementActions.deleteUser(payload)),
  setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIActions.setGlobalRowsPerPage(rowsPerPage)),
});

export const UserManagementContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementComponent);