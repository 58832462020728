import * as React from 'react';
import { Grid } from '@material-ui/core';
import { CommonStyles } from '../../hooks/styles';
import dataList from '../../constants/dataList';
import MUIDataTable from "mui-datatables";
import Loading from '../../components/loading';
import SearchFilter from '../../components/searchFilter';
import moment from 'moment';

interface InventoryReportProps {
	authUser?: any
	inventoryReport: any[]
	inventoryReportRows: any
	globalRowsPerPage: any
	getInventoryReport: (payload: any) => void
	setGlobalRowsPerPage: (numRows: any) => void
}

export const InventoryReportComponent: React.FC<InventoryReportProps> = (props) => {
	const classes = CommonStyles();
	const firstRender = React.useRef(false);
	const [filters, setFilters] = React.useState<any>({completionDate: moment().format('YYYY-MM-DD')})
	const [sortOrder, setSortOrder] = React.useState(null as any)
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
  	const [pagination, setPageState] = React.useState({
		offset: 0,
		limit: props.globalRowsPerPage,
		page: 0
	});

	const columnData = [
		{
			label: "ID",
			name: "packsetId",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Description",
			name: "packsetDescription",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Quantity",
			name: "quantity",
			change: true,
			viewColumns: true,
			display: true
		},
	]

	const getTableColumns = () => {
		return columnData.map((column: any, index: number) => {
			return {
				label: column.label,
				name: column.name,
				options: {
					filter: true,
					display: tableStatePersist ? tableStatePersist.columns[index].display : column.display,
					change: column.change,
					sort: column.sort ?? true,
					viewColumns: column.viewColumns,
					...(column.customBodyRender) && { customBodyRender: column.customBodyRender },
				}
			}
		})
	}

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			filterProducts()
		}
		else {
			firstRender.current = false;
		}
	}, [pagination, sortOrder])

	const getFilter = (reset?: boolean) => {
		const dates = filters['completionDate']
		const formattedStartDate = moment(dates.startDate || dates).local().format("YYYY-MM-DD")
		const formattedEndDate = moment(dates.endDate || dates).local().format("YYYY-MM-DD")
		return {startDate: formattedStartDate, endDate: formattedEndDate}
	}

	const filterProducts = (reset?: boolean) => {
		props.getInventoryReport({date: getFilter(reset)})
		
	}

	const showLoadingMsg = () => (
		!props.authUser && props.inventoryReport.length === 0
	)

	return (<div>
		{showLoadingMsg() ? <div><Loading message="" /></div> : <div>
			<div className={classes.searchWrap}>
				<Grid container>
					<Grid item xs={'auto'}>
						<SearchFilter
							config={[
								{
									label: 'Workgroup Completion Date',
									name: 'completionDate',
									type: 'dateRange'
								},
							]}
							onFilterChange={setFilters}
							filters={filters}
							handleSearch={() => filterProducts()}
						/>
					</Grid>
				</Grid>
			</div>
			<div>
				<MUIDataTable
					data={props.inventoryReport}
					columns={getTableColumns()}
					options={{
						rowsPerPage: props.globalRowsPerPage,
						rowsPerPageOptions: dataList.PageLimitOptions,
						onChangeRowsPerPage: (numberOfRows: number) => {
							setPageState({ offset: 0, limit: numberOfRows, page: 0 });
							props.setGlobalRowsPerPage(numberOfRows);
						},
						filterType: "dropdown",
						responsive: "simple",
						filter: false,
						search: false,
						download: true,
						print: true,
						selectableRows: 'none',
						serverSide: false,
						page: pagination.page,
						count: props.inventoryReportRows,
						onColumnSortChange: (changedColumn: string, direction: string) => {
							setSortOrder(`${changedColumn} ${direction.toUpperCase()}`)
						},
						onDownload: (buildHead: any, buildBody: any, columns: any) => {
							const filter = getFilter()
							const exportOptions = {
								columns: columns,
								timestampFormat: 'YYYY-MM-DD hh:ss a',
								fileName: 'inventory-report'
							}
							props.getInventoryReport({ date: filter, exportOptions: exportOptions })
							return false
						},
						onTableChange: (tableAction: any, tableState: any) => {
							switch (tableAction) {
								case 'changePage':
									if (tableState.page > pagination.page) {
										setPageState({ offset: pagination.offset + props.globalRowsPerPage, limit: props.globalRowsPerPage, page: tableState.page });
									}
									else if (tableState.page < pagination.page) {
										setPageState({ offset: pagination.offset - props.globalRowsPerPage, limit: props.globalRowsPerPage, page: tableState.page });
									}
									break;
								case 'viewColumnsChange':
									setTableStatePersist(tableState);
									break;
							}
						},
					}}
				/>
			</div>
		</div>}
	</div>)
}