export default {
  PageLimitOptions: [10, 20, 30, 50, 100],

  BooleanYesNo: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],

  Priority: [
    { label: "High", value: 1 },
    { label: "Medium", value: 2 },
    { label: "Low", value: 3 },
  ],

  BagTypes: [
    { label: "Small", value: "Small" },
    { label: "Medium", value: "Medium" },
    { label: "Large", value: "Large" },
  ],

  ServiceTypes: [
    { label: "Ground", value: "Ground" },
    { label: "Next Day", value: "Next Day" },
    { label: "Air", value: "Air" },
  ],

  DateFormats: [
    { "label": "YYYY-MM-DD", "value": "yyyy-MM-DD" },
    { "label": "YY-MM-DD", "value": "YY-MM-DD" },
    { "label": "MM-DD-YYYY", "value": "MM-DD-yyyy" },
    { "label": "MM-DD-YY", "value": "MM-DD-YY" },
    { "label": "DD-MM-YYYY", "value": "DD-MM-yyyy" },
    { "label": "DD-MM-YY", "value": "DD-MM-YY" },
  ],

  PickerDateFormats: [
    { "label": "YYYY-MM-DD", "value": "yyyy-MM-dd" },
    { "label": "YY-MM-DD", "value": "yy-MM-dd" },
    { "label": "MM-DD-YYYY", "value": "MM-dd-yyyy" },
    { "label": "MM-DD-YY", "value": "MM-dd-yy" },
    { "label": "DD-MM-YYYY", "value": "dd-MM-yyyy" },
    { "label": "DD-MM-YY", "value": "dd-MM-yy" },
  ],

  TimeFormats: [
    { "label": "24 Hour", "value": "HH:mm:ss zz" },
    { "label": "12 Hour", "value": "hh:mm:ssA zz" },
  ],

  Warehouses: [
    { label: 'NCDC', value: 'NCDC' },
    { label: 'SCDC', value: 'SCDC' },
    { label: 'TXDC', value: 'TXDC' }
  ],

  InitiatedByList: [
    { "label": "MACHINE", "value": "MACHINE" },
    { "label": "USER", "value": "USER" }
  ],

  ProcessTypes: [
    { "label": "Bagout", "value": "Bagout" },
    { "label": "Packout", "value": "Packout" },
    { "label": "Supply", "value": "Supply" },
    { "label": "Inner Box", "value": "Inner Box" },
  ],

  StateCodes: [
    {label:"AL", value: "Alabama"},
    {label:"AK", value: "Alaska"},
    {label:"AS", value: "American Samoa"},
    {label:"AZ", value: "Arizona"},
    {label:"AR", value: "Arkansas"},
    {label:"CA", value: "California"},
    {label:"CO", value: "Colorado"},
    {label:"CT", value: "Connecticut"},
    {label:"DE", value: "Delaware"},
    {label:"DC", value: "District Of Columbia"},
    {label:"FM", value: "Federated States Of Micronesia"},
    {label:"FL", value: "Florida"},
    {label:"GA", value: "Georgia"},
    {label:"GU", value: "Guam"},
    {label:"HI", value: "Hawaii"},
    {label:"ID", value: "Idaho"},
    {label:"IL", value: "Illinois"},
    {label:"IN", value: "Indiana"},
    {label:"IA", value: "Iowa"},
    {label:"KS", value: "Kansas"},
    {label:"KY", value: "Kentucky"},
    {label:"LA", value: "Louisiana"},
    {label:"ME", value: "Maine"},
    {label:"MH", value: "Marshall Islands"},
    {label:"MD", value: "Maryland"},
    {label:"MA", value: "Massachusetts"},
    {label:"MI", value: "Michigan"},
    {label:"MN", value: "Minnesota"},
    {label:"MS", value: "Mississippi"},
    {label:"MO", value: "Missouri"},
    {label:"MT", value: "Montana"},
    {label:"NE", value: "Nebraska"},
    {label:"NV", value: "Nevada"},
    {label:"NH", value: "New Hampshire"},
    {label:"NJ", value: "New Jersey"},
    {label:"NM", value: "New Mexico"},
    {label:"NY", value: "New York"},
    {label:"NC", value: "North Carolina"},
    {label:"ND", value: "North Dakota"},
    {label:"MP", value: "Northern Mariana Islands"},
    {label:"OH", value: "Ohio"},
    {label:"OK", value: "Oklahoma"},
    {label:"OR", value: "Oregon"},
    {label:"PW", value: "Palau"},
    {label:"PA", value: "Pennsylvania"},
    {label:"PR", value: "Puerto Rico"},
    {label:"RI", value: "Rhode Island"},
    {label:"SC", value: "South Carolina"},
    {label:"SD", value: "South Dakota"},
    {label:"TN", value: "Tennessee"},
    {label:"TX", value: "Texas"},
    {label:"UT", value: "Utah"},
    {label:"VT", value: "Vermont"},
    {label:"VI", value: "Virgin Islands"},
    {label:"VA", value: "Virginia"},
    {label:"WA", value: "Washington"},
    {label:"WV", value: "West Virginia"},
    {label:"WI", value: "Wisconsin"},
    {label:"WY", value: "Wyoming"}
  ],

  Lanes: [
    { label: '2', value: '2' },
    { label: '3', value: '3' },
  ],

  GroupStatus: [
    { label: 'PENDING', value: 'PENDING' },
    { label: 'ASSIGNED', value: 'ASSIGNED' },
  ],

  WorkgroupStatus: [
    { label: 'READY', value: 'READY' },
    { label: 'PROCESSING', value: 'PROCESSING' },
    { label: 'IN PROGRESS', value: 'IN PROGRESS' },
    { label: 'COMPLETED', value: 'COMPLETED' },
    { label: 'MANIFEST_ERROR', value: 'MANIFEST_ERROR' },
  ],
}


