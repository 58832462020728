import React from 'react';
import { connect } from 'react-redux';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { CssBaseline, Drawer, AppBar, Toolbar, List, IconButton, MenuList, Fade, Modal, Grid, Button, FormControl } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SideMenuBar from './sideBar';
import MenuListComposition from './menuList';
import { IMAGES_URL } from '../../../constants/Images';
import { BasePageStyles, CommonStyles, getModalClasses, getModalStyle } from '../../../hooks/styles'
import { Alert, AlertTitle } from '@material-ui/lab';
import { UIActions, UserManagementActions } from '../../../redux/actions';
import { useHistory } from "react-router-dom";
import Loading from '../../../components/loading';


const BasePage = (props: any) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })

  const initNavDrawerState = () => {
    let open = isDesktopOrLaptop ? true : false
    if (props.navDrawerOpen === false) {
      open = false
    }
    return open
  }

  const history = useHistory();
  const classes = BasePageStyles();
  const commonClasses = CommonStyles();
  const modalClasses = getModalClasses();
  const [modalStyle] = React.useState(getModalStyle());
  const [open, setOpen] = React.useState(initNavDrawerState());
  const [urlIsProcessed, setUrlIsProcessed] = React.useState(false);
  const [sessionRefreshError, setSessionRefreshError] = React.useState(false)
  const [secondsUntilLogout, setSecondsUntilLogout] = React.useState<number>(30)
  const [eventListenerActive, setEventListenerActive] = React.useState(false)
  const [timer, setTimer] = React.useState<any>(null)

  React.useEffect(() => {
    if (!urlIsProcessed) {
      props.setUrlFilter(window.location.search)
      setUrlIsProcessed(true)
    }
  }, [urlIsProcessed])

  const intervalPoller = () => {
    const intervalPoller = setInterval(() => {
      props.pingUserService()
    }, 60000)
    return intervalPoller
  }

  React.useEffect(() => {
    if (props.authUser && !props.dataIsPreloaded) {
      props.preloadData({poller: intervalPoller})
    }
  }, [props.authUser])


  const handleDrawerOpen = () => {
    setOpen(true);
    props.setNavDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.setNavDrawer(false);
  };

  const clearError = () => {

  }

  const logoutTimer = () => {
    let seconds: number = 30
    const interval = setInterval(() => {
      if (seconds === 0) {
        clearInterval(interval)
        handleLogout()
        return
      }
      seconds = seconds - 1
      setSecondsUntilLogout(seconds)
    }, 1000)
    return interval
  }

  const onSessionExpired = () => {
    setTimer(logoutTimer())
  }

  const onSessionRefreshError = () => {
    setSessionRefreshError(true)
    setTimeout(() => {
      handleLogout()
    }, 3000)
  }

  const handleLogout = () => {
    props.logout(history)
  }

  const handleBackgroundRefreshSession = React.useCallback(() => {
    props.refreshSessionInBackground({})
    setEventListenerActive(true)
    if (!props.authUser) {
      document.body.removeEventListener('mousemove', handleBackgroundRefreshSession, true)
    }
  }, [])

  React.useEffect(() => {
    !eventListenerActive && !props.sessionExpired && document.body.addEventListener('mousemove', handleBackgroundRefreshSession, true);
  })

  React.useEffect(() => {
    if (props.sessionExpired) {
      document.body.removeEventListener('mousemove', handleBackgroundRefreshSession, true)
      setEventListenerActive(false)
      onSessionExpired()
    }
  }, [props.sessionExpired])

  return (


    <div className={clsx(classes.root, (props.isLoading ? "show-loader" : ""))}>
      <CssBaseline />

      <AppBar position="absolute" elevation={0} className={clsx(classes.appBar, open && classes.appBarShift)}>{/* app top bar */}
        <Toolbar className={isDesktopOrLaptop ? classes.toolbar : classes.toolbarMobile}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx((isDesktopOrLaptop ? classes.menuButton : classes.menuButtonMobile), open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <MediaQuery minDeviceWidth={1224}>
            {props.title && <MenuList className={classes.topmenu}><h3>{props.title}</h3></MenuList>}
            <MenuListComposition />{/* contains user avatar and profile edit link */}
          </MediaQuery>
          <MediaQuery maxDeviceWidth={1223}>
            <Fade in={!open}>
              <div style={{
                width: '73%',
                height: '56px',
              }}>
                {props.title && <h3 className={classes.pageTitle} style={props.selectedCustomer ? { margin: '5px 0 0 0' } : {}}>{props.title}</h3>}
              </div>
            </Fade>
            <MenuListComposition />
          </MediaQuery>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.leftColumn, classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        elevation={0}
      >
        <div className={classes.toolbarIcon}>
          <img src={IMAGES_URL.NUFACTOR_LOGO} className="logo-image" alt="nufactor logo" />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <List style={{
          height: '100%'
        }}>
          <SideMenuBar open={open} setOpen={setOpen} />
        </List>

      </Drawer>

      {urlIsProcessed && <main className={classes.content}>
        {props.children}
        {props.errorMessage &&
          <div onClick={clearError} className={classes.errorAlert}>
            <Alert severity={props.errorMessage.severity}>
              <AlertTitle>{props.errorMessage.title}</AlertTitle>
              {props.errorMessage.errorMessage}
            </Alert>
          </div>
        }
      </main>}

      <Modal
        open={props.sessionExpired || false}
        onClose={(e: any, reason: string) => {
          if (reason === 'escapeKeyDown' || reason === 'backdropClick') return
          props.setSessionExpired(false)
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={modalClasses.paper}>
          {!sessionRefreshError && <p>Your session has expired. Logging out in {secondsUntilLogout} seconds.</p>}
          {sessionRefreshError && <p className={commonClasses.errorMsg}>Sorry, your session could not be refreshed. Logging out now.</p>}
          {!sessionRefreshError && <Grid container spacing={0}>
            <Grid item xs>
              <FormControl className={commonClasses.formControl}>
                <Button
                  className={commonClasses.viewAllBtn}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    props.refreshSession({ onError: onSessionRefreshError })
                    clearInterval(timer)
                  }}
                >
                  Stay Logged In
                </Button>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl className={commonClasses.formControl}>
                <Button
                  className={commonClasses.cancelBtn}
                  variant="contained"
                  color="default"
                  onClick={() => {
                    handleLogout()
                  }}
                >
                  Sign Out
                </Button>
              </FormControl>
            </Grid>
          </Grid>}
        </div>
      </Modal>
      {props.isLoading ? <Loading message="Loading..." /> : ''}
    </div>

  );
};

const mapStateToProps = (state: any) => ({
  activeMenu: null, //state.ui.menuState,
  isLoading: state.ui.isLoading,
  errorMessage: null, //state.ui.errorMessage,
  navDrawerOpen: true, //state.ui.navDrawerOpen,
  selectedTheme: 'light', //state.user.selectedTheme
  sessionExpired: state.ui.sessionExpired,
  authUser: state.userManagement.authUser,
  dataIsPreloaded: state.ui.dataIsPreloaded
});

const mapDispatchToProps = (dispatch: any) => ({
  setNavDrawer: (open: boolean) => { },
  setUrlFilter: (queryString: string) => { },
  setSessionExpired: (flag: boolean) => dispatch(UIActions.setSessionExpired(flag)),
  refreshSession: (payload: any) => dispatch(UserManagementActions.refreshSession(payload)),
  refreshSessionInBackground: () => dispatch(UserManagementActions.refreshSessionInBackground()),
  clearAuthUser: () => dispatch(UserManagementActions.setAuthUser(null)),
  preloadData: (payload: any) => dispatch(UIActions.preLoadData(payload)),
  logout: (history: any) => dispatch(UserManagementActions.logout(history)),
  pingUserService: () => dispatch(UserManagementActions.pingUserService())
})

export default connect(mapStateToProps, mapDispatchToProps)(BasePage);

