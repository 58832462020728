import { takeLatest, debounce } from 'redux-saga/effects';
import { UI, PRODUCTS, USER_MANAGEMENT, RX, GROUP, WORKGROUP, REPORT } from '../constants/actions';
import * as ui from './ui.saga';
import * as product from './product.saga';
import * as userManagement from './user-management.saga';
import * as rx from './rx.saga';
import * as group from './group.saga';
import * as workgroup from './workgroup.saga'
import * as report from './report.saga'


export default function* rootSaga() {
    yield takeLatest(UI.NAVIGATE, ui.updateUINavigateState);
    yield takeLatest(UI.PRELOAD_DATA, ui.preLoadData);

    yield takeLatest(USER_MANAGEMENT.LOGIN, userManagement.login);
    yield debounce(20000, USER_MANAGEMENT.REFRESH_SESSION_IN_BACKGROUND, userManagement.refreshSession);
    yield takeLatest(USER_MANAGEMENT.REFRESH_SESSION, userManagement.refreshSession);
    yield takeLatest(USER_MANAGEMENT.LIST_REQUEST, userManagement.getUsers);
    yield takeLatest(USER_MANAGEMENT.INVITE_USER, userManagement.inviteUser);
    yield takeLatest(USER_MANAGEMENT.UPDATE_USER, userManagement.updateUser);
    yield takeLatest(USER_MANAGEMENT.DELETE_USER, userManagement.deleteUser);
    yield takeLatest(USER_MANAGEMENT.LOGOUT, userManagement.logout);
    yield takeLatest(USER_MANAGEMENT.PING_USER_SERVICE, userManagement.pingUserService);

    yield takeLatest(RX.GET_ALERT_TRANSACTIONS, rx.getAlertTransactions);
    yield takeLatest(RX.GET_RX_EXCEPTION_COUNT, rx.getRxExceptionsCount);
    yield takeLatest(RX.GET_RX_LIST, rx.getRxList);
    yield takeLatest(RX.UPDATE, rx.updateRx);
    yield takeLatest(RX.RESOLVE, rx.resolveRx);
    yield takeLatest(RX.CANCEL, rx.cancelRx);
    yield takeLatest(RX.IMPORT_REPROCESS, rx.importReprocess);

    yield takeLatest(GROUP.GET_LIST, group.getGroupList);
    yield takeLatest(GROUP.GET_RX_LIST, group.getRxList);
    yield takeLatest(GROUP.GET_GROUP_DETAILS, group.getGroupDetails);
    yield takeLatest(GROUP.UPDATE_GROUP_SERVICE_TYPE, group.updateGroupServiceType);

    yield takeLatest(WORKGROUP.GET_LIST, workgroup.getWorkgroupList);
    yield takeLatest(WORKGROUP.CREATE, workgroup.createWorkgroup);
    yield takeLatest(WORKGROUP.UPDATE, workgroup.updateWorkgroup);
    yield takeLatest(WORKGROUP.REPROCESS, workgroup.reprocessWorkgroup);

    yield takeLatest(PRODUCTS.LIST_REQUEST, product.getProducts);
    yield takeLatest(PRODUCTS.CREATE_PRODUCT, product.createProduct);
    yield takeLatest(PRODUCTS.UPDATE_PRODUCT, product.updateProduct);
    yield takeLatest(PRODUCTS.DELETE_PRODUCT, product.deleteProduct);

    yield takeLatest(PRODUCTS.GET_PACKING_ITEMS, product.getPackingItems);
    yield takeLatest(PRODUCTS.CREATE_PACKING_ITEM, product.createPackingItem);
    yield takeLatest(PRODUCTS.UPDATE_PACKING_ITEM, product.updatePackingItem);
    yield takeLatest(PRODUCTS.DELETE_PACKING_ITEM, product.deletePackingItem);

    yield takeLatest(PRODUCTS.GET_PACK_SETS, product.getPackSets);
    yield takeLatest(PRODUCTS.CREATE_PACK_SET, product.createPackSet);
    yield takeLatest(PRODUCTS.UPDATE_PACK_SET, product.updatePackSet);
    yield takeLatest(PRODUCTS.DELETE_PACK_SET, product.deletePackSet);

    yield takeLatest(REPORT.GET_INVENTORY_REPORT, report.getInventoryReport);

}
