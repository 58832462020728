import Api from "../lib/api";
import { WORKGROUPS } from "../constants/api.url";

export class WorkgroupService {

  private static instance: WorkgroupService;
  private constructor() { }

  public static getInstance(): WorkgroupService {
    if (!WorkgroupService.instance) {
      WorkgroupService.instance = new WorkgroupService();
    }
    return WorkgroupService.instance;
  }

  public getWorkgroups = async (filter: any) : Promise<any> => {
    const params = [{key: 'filter', value: JSON.stringify(filter)}];
    return await Api.get(WORKGROUPS.URL, params)
  }

  public createWorkgroup = async (payload: any) : Promise<any> => {
    return await Api.post(WORKGROUPS.URL, payload)
  }

  public updateWorkgroup = async (payload: any) : Promise<any> => {
    return await Api.patch(WORKGROUPS.URL + '/' + payload.workgroup.id, payload)
  }

  public reprocessWorkgroup = async (id: any) : Promise<any> => {
    return await Api.post(WORKGROUPS.URL + '/reprocess/' + id, id)
  }

}
