import Api from "../lib/api";
import { USER } from '../constants/api.url';

export class UserManagementService {

  private static instance: UserManagementService;
  private constructor() { }

  public static getInstance(): UserManagementService {
    if (!UserManagementService.instance) {
      UserManagementService.instance = new UserManagementService();
    }
    return UserManagementService.instance;
  }

  public login = async (payload: any) : Promise<any> => {
    return await Api.post(`${USER.URL}/login`, payload)
  }

  public getUsers = async (filter: any) : Promise<any> => {
    return await Api.get(`${USER.URL}/list`)
  }

  public inviteUser = async (payload: any) : Promise<any> => {
    return await Api.post(USER.URL, payload)
  }

  public updateUser = async (payload: any) : Promise<any> => {
    return await Api.patch(`${USER.URL}/${payload.id}`, payload)
  }

  public deleteUser = async (payload: any | null): Promise<any> => {
    await Api.delete(USER.URL + `/${payload}`, {});
  }

  public getUserById = async (userId: string): Promise<any> => {
    return await Api.get(USER.URL + `/${userId}`)
  }

}
