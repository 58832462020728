import { makeStyles } from '@material-ui/core/styles';

export const ChartStyles = makeStyles((theme) => ({

    container: {
      display: 'flex',
      marginTop : '50px',
      padding : '40px',
      backgroundColor : '#fff'
    },


  }), { index: 1 });
  export default ChartStyles;
