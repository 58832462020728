import config from '../../config';

export const API_BASE_URL = {
  API_GATEWAY: config.apiGateway
}

export const USER = {
  URL: `${API_BASE_URL.API_GATEWAY}/user`,
}

export const PRODUCTS = {
  PRODUCTS_URL: `${API_BASE_URL.API_GATEWAY}/products`,
  PACKING_ITEMS_URL: `${API_BASE_URL.API_GATEWAY}/packing-items`,
  PACK_SETS_URL: `${API_BASE_URL.API_GATEWAY}/pack-sets`,
  SERVICE_TYPES_URL: `${API_BASE_URL.API_GATEWAY}/service-types`,
  ADDRESS_TYPES_URL: `${API_BASE_URL.API_GATEWAY}/address-types`,
}

export const RX = {
  RX_URL: `${API_BASE_URL.API_GATEWAY}/rx`,
  RX_COUNT_URL: `${API_BASE_URL.API_GATEWAY}/rx/count`,
  RX_RESOLVE_URL: `${API_BASE_URL.API_GATEWAY}/rx/resolve`,
  IMPORT_REPROCESS: `${API_BASE_URL.API_GATEWAY}/import/cpr/reprocess`,
}

export const GROUPS = {
  URL: `${API_BASE_URL.API_GATEWAY}/groups`,
}

export const WORKGROUPS = {
  URL: `${API_BASE_URL.API_GATEWAY}/workgroups`,
}

export const REPORTS = {
  URL: `${API_BASE_URL.API_GATEWAY}/reports`,
  INVENTORY_URL: `${API_BASE_URL.API_GATEWAY}/reports/inventory`,
}
