import { connect } from 'react-redux';
import { GroupActions, RxActions, UIActions } from '../../redux/actions';
import { ShipmentGroupsComponent } from './shipment-groups.component';

const mapStateToProps = (state: any) => ({
  authUser: state.userManagement.authUser,
  shipmentGroups: state.group.groupList ? state.group.groupList.result : [],
  totalShipmentGroups: state.group.groupList ? state.group.groupList.total_records : 0,
  globalRowsPerPage: state.ui.globalRowsPerPage ? state.ui.globalRowsPerPage : 10,
});

const mapDispatchToProps = (dispatch: any) => ({
  getShipmentGroups: (filter: any) => dispatch(GroupActions.getList(filter)),
  getGroupRx: (filter: any) => dispatch(RxActions.getRxList(filter)),
  setGlobalRowsPerPage: (rowsPerPage: number) => dispatch(UIActions.setGlobalRowsPerPage(rowsPerPage)),
});

export const ShipmentGroupsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShipmentGroupsComponent);