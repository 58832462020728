import { action } from 'typesafe-actions';
import { PRODUCTS } from '../constants/actions';

export const setPreloadedProducts   = (data: any) => action(PRODUCTS.SET_PRELOADED_PRODUCTS, data);

export const getProducts   = (filter: any) => action(PRODUCTS.LIST_REQUEST, filter);
export const setProducts   = (data: any) => action(PRODUCTS.LIST_RESPONSE, data);
export const createProduct = (payload: any) => action(PRODUCTS.CREATE_PRODUCT, payload);
export const updateProduct = (payload: any) => action(PRODUCTS.UPDATE_PRODUCT, payload);
export const deleteProduct = (payload: any) => action(PRODUCTS.DELETE_PRODUCT, payload);

export const getPackingItems   = (filter: any) => action(PRODUCTS.GET_PACKING_ITEMS, filter);
export const setPackingItems   = (data: any) => action(PRODUCTS.SET_PACKING_ITEMS, data);
export const createPackingItem = (payload: any) => action(PRODUCTS.CREATE_PACKING_ITEM, payload);
export const updatePackingItem = (payload: any) => action(PRODUCTS.UPDATE_PACKING_ITEM, payload);
export const deletePackingItem = (payload: any) => action(PRODUCTS.DELETE_PACKING_ITEM, payload);

export const getPackSets   = (filter: any) => action(PRODUCTS.GET_PACK_SETS, filter);
export const setPackSets   = (data: any) => action(PRODUCTS.SET_PACK_SETS, data);
export const createPackSet = (payload: any) => action(PRODUCTS.CREATE_PACK_SET, payload);
export const updatePackSet = (payload: any) => action(PRODUCTS.UPDATE_PACK_SET, payload);
export const deletePackSet = (payload: any) => action(PRODUCTS.DELETE_PACK_SET, payload);