import { call, put } from "redux-saga/effects";
import { Constants } from "../../constants/Constants";
import { ProductActions, UIActions } from "../actions";
import { ProductService } from "../services/product.service";
import { applyExportOptions, downloadCsvFile } from '../lib/dataExportUtils';

export function* getProducts(action: ReturnType<typeof ProductActions.getProducts>) {
    try {
        yield put(UIActions.showLoader(true));
        const { data } = yield call(ProductService.getInstance().getProducts, action.payload.filter);

        if (action.payload.exportOptions) {
            const dataWithOptions = applyExportOptions(action.payload.exportOptions, data.result)
            downloadCsvFile(action.payload.exportOptions.fileName, dataWithOptions)
            yield put(UIActions.showLoader(false));
            return;
        }

        yield put(ProductActions.setProducts(data));
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (!action.payload.exportOptions) {
            yield put(ProductActions.setProducts(null));
        }
        if (err instanceof Error) {
            const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            console.log(errorMessage, err);
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}

export function* createProduct(action: ReturnType<typeof ProductActions.createProduct>): any {
    try {
        yield put(UIActions.showLoader(true));
        yield call(ProductService.getInstance().createProduct, action.payload.requestBody);
        action.payload.success()
        //Preload list of products for autocomplete
        const createProductsResponse = yield call(ProductService.getInstance().getProducts, {where: {active: true}, include: ['packSets', 'productDimensions']})
        yield put(ProductActions.setPreloadedProducts(createProductsResponse.data))
        yield put(UIActions.showLoader(false));
    } catch (err) {
        action.payload.error()
        if (err instanceof Error) {
            const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            console.log(errorMessage, err);
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}

export function* updateProduct(action: ReturnType<typeof ProductActions.updateProduct>): any {
    try {
        yield put(UIActions.showLoader(true));
        yield call(ProductService.getInstance().updateProduct, action.payload.requestBody);
        action.payload.success()
        //Preload list of products for autocomplete
        const updateProductsResponse = yield call(ProductService.getInstance().getProducts, {where: {active: true}, include: ['packSets', 'productDimensions']})
        yield put(ProductActions.setPreloadedProducts(updateProductsResponse.data))
        yield put(UIActions.showLoader(false));
    } catch (err) {
        action.payload.error()
        if (err instanceof Error) {
            const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            console.log(errorMessage, err);
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}

export function* deleteProduct(action: ReturnType<typeof ProductActions.deleteProduct>): any {
    try {
        yield put(UIActions.showLoader(true));
        yield call(ProductService.getInstance().deleteProduct, action.payload);
        action.payload.success();
        //Preload list of products for autocomplete
        const deleteProductsResponse = yield call(ProductService.getInstance().getProducts, {where: {active: true}, include: ['packSets', 'productDimensions']})
        yield put(ProductActions.setPreloadedProducts(deleteProductsResponse.data))
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (err instanceof Error) {
            const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            console.log(errorMessage, err);
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}

export function* getPackingItems(action: ReturnType<typeof ProductActions.getPackingItems>) {
    try {
        yield put(UIActions.showLoader(true));
        const { data } = yield call(ProductService.getInstance().getPackingItems, action.payload.filter);

        if (action.payload.exportOptions) {
            const dataWithOptions = applyExportOptions(action.payload.exportOptions, data.result)
            downloadCsvFile(action.payload.exportOptions.fileName, dataWithOptions)
            yield put(UIActions.showLoader(false));
            return;
        }

        yield put(ProductActions.setPackingItems(data));
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (!action.payload.exportOptions) {
            yield put(ProductActions.setPackingItems(null));
        }
        if (err instanceof Error) {
            const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            console.log(errorMessage, err);
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}

export function* createPackingItem(action: ReturnType<typeof ProductActions.createPackingItem>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(ProductService.getInstance().createPackingItem, action.payload.requestBody);
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        action.payload.error()
        if (err instanceof Error) {
            const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            console.log(errorMessage, err);
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}

export function* updatePackingItem(action: ReturnType<typeof ProductActions.updatePackingItem>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(ProductService.getInstance().updatePackingItem, action.payload.requestBody);
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        action.payload.error()
        if (err instanceof Error) {
            const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            console.log(errorMessage, err);
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}

export function* deletePackingItem(action: ReturnType<typeof ProductActions.deletePackingItem>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(ProductService.getInstance().deletePackingItem, action.payload);
        action.payload.success();
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (err instanceof Error) {
            const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            console.log(errorMessage, err);
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}

export function* getPackSets(action: ReturnType<typeof ProductActions.getPackSets>) {
    try {
        yield put(UIActions.showLoader(true));
        const { data } = yield call(ProductService.getInstance().getPackSets, action.payload.filter);
        
        if (action.payload.exportOptions) {
            const dataWithOptions = applyExportOptions(action.payload.exportOptions, data.result)
            downloadCsvFile(action.payload.exportOptions.fileName, dataWithOptions)
            yield put(UIActions.showLoader(false));
            return;
        }
        
        yield put(ProductActions.setPackSets(data));
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (!action.payload.exportOptions) {
            yield put(ProductActions.setPackSets(null));
        }
        if (err instanceof Error) {
            const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            console.log(errorMessage, err);
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}

export function* createPackSet(action: ReturnType<typeof ProductActions.createPackSet>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(ProductService.getInstance().createPackSet, action.payload.requestBody);
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        action.payload.error()
        if (err instanceof Error) {
            const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            console.log(errorMessage, err);
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}

export function* updatePackSet(action: ReturnType<typeof ProductActions.updatePackSet>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(ProductService.getInstance().updatePackSet, action.payload.requestBody);
        action.payload.success()
        yield put(UIActions.showLoader(false));
    } catch (err) {
        action.payload.error()
        if (err instanceof Error) {
            const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            console.log(errorMessage, err);
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}

export function* deletePackSet(action: ReturnType<typeof ProductActions.deletePackSet>) {
    try {
        yield put(UIActions.showLoader(true));
        yield call(ProductService.getInstance().deletePackSet, action.payload);
        action.payload.success();
        yield put(UIActions.showLoader(false));
    } catch (err) {
        if (err instanceof Error) {
            const errorMessage = err.message.includes('403') ? Constants.ALERT.NOT_AUTHORIZED : Constants.ALERT.SERVER_ERROR;
            console.log(errorMessage, err);
            if (err.message === 'JWT_EXPIRED') {
                yield put(UIActions.setSessionExpired(true));
            }
        }
    }
}
